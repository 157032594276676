import Gauge from '../../uikit/guageChart'
import { getCurrentDate } from '../../utils/dateFormatter';
import useApi from '../../utils/hooks/useApi';
import useQueryParam from '../../utils/hooks/useQueryParam';

const TargetStats = () => {
    const [dateParam] = useQueryParam('date');
    const currentDate = dateParam || getCurrentDate();

    const { data: membersStats, error, isLoading, refetch } = useApi({
        queryKey: ['membersStats', currentDate],
        url: `/memberstoeomtarget?page=1&page_size=10&paginate=false&current_date=${currentDate}`,
        staleTime: 7200000, // 2 hours in milliseconds
        cacheTime: 7200000, // 2 hours in milliseconds
        method: 'GET',
        onSuccess: (data) => {
        },
        onError: (error) => {
            console.error('Error fetching data:', error);
        },
    });

    let statusLabel;
    if (membersStats?.data?.[0]?.difference > 0) {
        statusLabel = "Above Target";
    } else if (membersStats?.data?.[0]?.difference < 0) {
        statusLabel = "Shortfall";
    } else {
        statusLabel = "On Target";
    }

    return (
        <div className="w-full container flex justify-center items-center flex-col max-w-[100vw] ring-gray-200 dark:ring-moon-700 ring-2 py-1.5 px-2 rounded-xl bg-white dark:bg-moon-900">
            <div className="">
                <Gauge differenceLabel={statusLabel} difference={membersStats?.data?.[0]?.difference || null} currentValue={membersStats?.data?.[0]?.sum_member_count || null} currentLabel='Current Members' targetLabel='Total Members Target' targetValue={membersStats?.data?.[0]?.max_eom_member_goal || null} />
            </div>
        </div>
    )
}

export default TargetStats