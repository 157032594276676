/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'react';
import useQueryParam from '../../utils/hooks/useQueryParam';
import { getCurrentDate } from '../../utils/dateFormatter';

const DateIcon = () => {
    const [dateParam, setDateParam] = useQueryParam('date');
    const inputRef = useRef(null); // Create a ref for the input field

    // Set initial date or fallback to today's date
    const initialDate = dateParam ? new Date(dateParam) : new Date();
    const [selectedDate, setSelectedDate] = useState(initialDate);

    // Update selectedDate when dateParam changes
    useEffect(() => {
        if (dateParam) {
            setSelectedDate(new Date(dateParam));
        } else {
            setSelectedDate(new Date()); // Reset to today if dateParam is not present
        }
    }, [dateParam]);

    const handleDateChange = (event) => {
        const newDate = new Date(event.target.value);
        setSelectedDate(newDate);
        setDateParam(newDate.toISOString().substring(0, 10)); // Set query param as 'YYYY-MM-DD'
    };

    const preventTyping = (event) => {
        event.preventDefault();
    };

    const handleIconClick = () => {
        if (inputRef.current) {
            inputRef.current.focus(); // Focus on the date input to trigger the date picker
        }
    };

    return (
        <div onClick={handleIconClick} className="!cursor-pointer relative border rounded-lg max-w-[45px] px-2 stacked-shadow dark:bg-gray-800 bg-white ">
            <time className="!cursor-pointer flex justify-center items-center flex-col w-full h-full bg-white dark:bg-gray-800 rounded-md">
                <strong className="text-[#a51d36] text-xs uppercase !cursor-pointer">
                    {selectedDate.toLocaleDateString('en-US', { month: 'short' })}
                </strong>
                <span className="text-[#2f2f2f] text-sm dark:text-gray-300 !cursor-pointer">
                    {selectedDate.toLocaleDateString('en-US', { day: '2-digit' })}
                </span>
                <input
                    type="date"
                    ref={inputRef}
                    min="2024-07-31"
                    max={getCurrentDate()}
                    value={selectedDate.toISOString().substring(0, 10)}
                    onChange={handleDateChange}
                    onKeyDown={preventTyping} // Disable typing
                    className=" text-3xl  absolute inset-0 w-[35px] h-full opacity-0 !cursor-pointer"
                />
            </time>
        </div>
    );
};

export default DateIcon;
