/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import SortingIcon from './SortingIcon';

function SimpleClientTable({ data, isPaginationRequired = true, pageSize = 10, columns, thClassName = '', handleRowClick = () => { } }) {
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: pageSize,
  });

  const table = useReactTable({
    columns,
    data,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  return (
    <div className="relative">
      <div className="overflow-x-auto">
        <table className="border-y rounded-xl border-gray-200 dark:border-moon-700 divide-y divide-gray-200 dark:divide-moon-700 [&>tbody:first-of-type]:!border-t-0 w-full sortable">
          <thead className="z-[2] border-b border-gray-200 dark:border-moon-700">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-white dark:bg-moon-900">
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`${index === 0
                      ? 'sticky left-0 z-10 bg-white dark:bg-moon-900 text-left'
                      : header.column.columnDef.alignment === 'center' ? 'text-center' : 'text-end'
                      } min-w-[24px] no-sort py-3 px-1 2lg:pl-2.5 font-semibold text-sm text-gray-900 dark:text-moon-50 whitespace-nowrap ${thClassName}`}
                  >
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? `cursor-pointer select-none flex ${index === 0 ? 'justify-start' : header.column.columnDef.alignment === 'center' ? 'justify-center' : 'justify-end'} items-center md:gap-2`
                          : `flex ${index === 0 ? 'justify-start' : header.column.columnDef.alignment === 'center' ? 'justify-center' : 'justify-end'} items-center md:gap-2`,
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <SortingIcon direction='asc' />,
                        desc: <SortingIcon direction='desc' />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200 min-w-full dark:divide-moon-700">
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id} onClick={() => handleRowClick(row)} className="hover:bg-gray-50 bg-white dark:bg-moon-900 hover:dark:bg-moon-800 text-sm !cursor-pointer">
                  {row.getVisibleCells().map((cell, index) => (
                    <td
                      key={cell.id}
                      className={`${index === 0
                        ? 'sticky left-0 z-10 bg-inherit text-left'
                        : cell.column.columnDef.alignment === 'center' ? 'text-center' : 'text-end'
                        } px-1 py-2.5 2lg:p-2.5 text-gray-900 dark:text-moon-50`}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="text-center text-gray-900 dark:text-moon-50 py-4">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {
        isPaginationRequired && table.getRowModel().rows.length > 0 && data?.length > 10 && (
          <div className="mt-4">
            <div className="flex flex-1 items-center justify-evenly sm:justify-between">
              {/* Pagination Info */}
              <div className="hidden sm:inline-flex text-xs text-gray-500 dark:text-gray-300">
                Showing {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} to{" "}
                {Math.min((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize, data.length)} of{" "}
                {data.length} results
              </div>

              {/* Pagination Controls */}
              <nav className="inline-flex items-center space-x-1 rounded-lg" role="navigation">
                {/* Previous Button */}
                <button
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  className={`relative inline-flex items-center rounded-lg p-2 h-8 ${table.getCanPreviousPage() ? 'text-gray hover:bg-gray-100 dark:text-gray-500 dark:hover:bg-gray-700' : 'text-gray-400'} `}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M12.293 16.707a1 1 0 010-1.414L7.414 10l4.879-4.879a1 1 0 00-1.414-1.414l-5.586 5.586a1 1 0 000 1.414l5.586 5.586a1 1 0 001.414 0z" clipRule="evenodd" />
                  </svg>
                </button>

                {/* Page Numbers */}
                {Array.from({ length: table.getPageCount() }, (_, index) => (
                  <button
                    key={index}
                    onClick={() => table.setPageIndex(index)}
                    className={`relative inline-flex items-center rounded-lg px-4 py-1.5 text-base font-semibold ${table.getState().pagination.pageIndex === index
                      ? 'bg-[#ee7725] text-white shadow-md'  // Active page
                      : ' text-gray-700 dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600' // Other pages
                      }`}
                  >
                    {index + 1}
                  </button>
                ))}

                {/* Next Button */}
                <button
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                  className={`relative inline-flex items-center rounded-lg p-2 h-8 ${table.getCanNextPage() ? 'text-gray hover:bg-gray-100 dark:text-gray-500 dark:hover:bg-gray-700' : 'text-gray-400'}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.707 3.293a1 1 0 000 1.414L12.586 10l-4.879 4.879a1 1 0 001.414 1.414l5.586-5.586a1 1 0 000-1.414l-5.586-5.586a1 1 0 00-1.414 0z" clipRule="evenodd" />
                  </svg>
                </button>
              </nav>

              {/* Page Size Selector */}
              <div className="hidden sm:inline-flex items-center gap-2">
                <span className="text-xs text-gray-500 dark:text-gray-300">Rows per page</span>
                <select
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => table.setPageSize(Number(e.target.value))}
                  className="bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-sm font-semibold rounded-lg px-2.5 py-1.5"
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}

SimpleClientTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageSize: PropTypes.number,
  isPaginationRequired: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SimpleClientTable;