import React from 'react';
import PropTypes from 'prop-types';

const SemiCircleDonutLegend = ({ data }) => (
    <div className="flex flex-wrap justify-center gap-5 pt-2">
        {data?.map((item) => (
            <div key={item.id} className="flex items-center">
                <div
                    className="w-3 h-3 rounded-full mr-2"
                    style={{ backgroundColor: item.color }}
                ></div>
                <span className="text-xs text-gray-600">{item.label}</span>
            </div>
        ))}
    </div>
);

SemiCircleDonutLegend.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
        })
    ),
};

export default SemiCircleDonutLegend;
