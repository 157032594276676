/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
import ToolTip from '../tooltip';

function Abbreviation({ title, children }) {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    // Detect if the device is a touch device
    setIsTouchDevice(navigator.maxTouchPoints > 0);
  }, []);


  return (
    <div
      className="inline-block"
    >
      {isTouchDevice ? (
        <>
          <ToolTip tooltipText={title}>{children}</ToolTip>
        </>
      ) : (
        <abbr
          className="cursor-help no-underline"
          title={title}
        >
          {children}
        </abbr>
      )}
    </div>
  );
}

export default Abbreviation;