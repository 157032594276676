/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import Button from '../../uikit/button';
import AddIcon from '../../common/ImageJSX/AddIcon';
import NumberSpinner from '../../uikit/numberSpinner';
import useApi from '../../utils/hooks/useApi';
import { FAKE_MULTIPLIERS_DATA } from '../../utils/dummyAPIResponse';

const MembershipCalculator = ({ currentPoints = 0 }) => {
    const [possibilities, setPossibilities] = useState([]);
    const [newMembers, setNewMembers] = useState([]);
    const [totalImpact, setTotalImpact] = useState(0);
    const [hasAddedPossibility, setHasAddedPossibility] = useState(false);
    const [hasAddedNewMember, setHasAddedNewMember] = useState(false);

    const { data = FAKE_MULTIPLIERS_DATA, error, isLoading, refetch } = useApi({
        queryKey: ['multipliers'],
        url: `/multipliers?page=1&page_size=10&paginate=false`,
        staleTime: 7200000, // 2 hours in milliseconds
        cacheTime: 7200000, // 2 hours in milliseconds
        method: 'GET',
        onSuccess: (data) => {
        },
        onError: (error) => {
            console.error('Error fetching data:', error);
        },
    });


    useEffect(() => {
        calculateTotalImpact();
    }, [possibilities, newMembers, currentPoints]);

    const addPossibility = () => {
        setPossibilities([
            ...possibilities,
            { currentTier: "", targetTier: "", members: 0, impact: 0 },
        ]);
        setHasAddedPossibility(true);
    };

    const removePossibility = (index) => {
        const updatedPossibilities = possibilities.filter((_, i) => i !== index);
        setPossibilities(updatedPossibilities);
        if (updatedPossibilities.length === 0) {
            setHasAddedPossibility(false);
        }
    };

    const handlePossibilityChange = (index, field, value) => {
        setPossibilities(
            possibilities.map((possibility, i) =>
                i === index ? { ...possibility, [field]: value } : possibility
            )
        );
    };

    const addNewMember = () => {
        setNewMembers([...newMembers, { tier: "", members: 0, impact: 0 }]);
        setHasAddedNewMember(true);
    };

    const removeNewMember = (index) => {
        const updatedNewMembers = newMembers.filter((_, i) => i !== index);
        setNewMembers(updatedNewMembers);
        if (updatedNewMembers.length === 0) {
            setHasAddedNewMember(false);
        }
    };

    const handleNewMemberChange = (index, field, value) => {
        setNewMembers(
            newMembers.map((newMember, i) =>
                i === index ? { ...newMember, [field]: value } : newMember
            )
        );
    };

    const calculateTotalImpact = () => {
        let total = 0;

        possibilities.forEach((possibility) => {
            const current = data?.data?.find(t => t.membership_tier === possibility.currentTier)?.multiplier || 1;
            const target = data?.data?.find(t => t.membership_tier === possibility.targetTier)?.multiplier || 1;
            const impact = possibility.members * (target - current);
            total += impact;
        });

        newMembers.forEach((newMember) => {
            const target = data?.data?.find(t => t.membership_tier === newMember.tier)?.multiplier || 1;
            const impact = newMember.members * target;
            total += impact;
        });

        setTotalImpact(total);
    };

    const finalProjectedPoints = Number(currentPoints) + Number(totalImpact);

    return (
        <div className="max-w-4xl mx-auto p-1 md:p-2 space-y-4 bg-white dark:bg-moon-900 text-gray-800 dark:text-moon-50">
            <div className="font-bold mt-4 text-gray-900 dark:text-moon-50 text-lg leading-7">
                Membership Tier Conversion Calculator
            </div>
            <div className="md:flex md:space-x-8 bg-[#f8fafc] dark:bg-moon-400/20 ring-gray-200 dark:ring-moon-700 ring-2 p-2 md:p-4  rounded-lg ">
                {/* Left section: Input forms */}
                <div className="md:w-3/5 space-y-4">
                    <h3 className="font-bold text-gray-900 dark:text-moon-50 text-base leading-7 mt-6">Add Tier Conversions</h3>
                    {possibilities.map((possibility, index) => (
                        <div key={index} className="bg-white dark:bg-moon-900 p-4 drop-shadow-md border rounded-md space-y-2 border-gray-200 dark:border-moon-700">
                            <div className='flex justify-between items-center pb-4 border-b border-gray-200 dark:border-moon-700'>
                                <div className='text-gray-500 dark:text-moon-200 font-medium text-base'>
                                    Tier Conversion {index + 1}
                                </div>
                                <div>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        startIcon={<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 12V17" stroke="#ff4d4d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M14 12V17" stroke="#ff4d4d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M4 7H20" stroke="#ff4d4d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#ff4d4d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#ff4d4d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>}
                                        onClick={() => removePossibility(index)}
                                    >
                                    </Button>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-2 text-sm">
                                <label>Current Tier</label>
                                <select
                                    className="p-2 border rounded-md bg-white dark:bg-moon-800 border-gray-300 dark:border-moon-700 text-gray-800 dark:text-moon-50"
                                    value={possibility.currentTier}
                                    onChange={(e) => handlePossibilityChange(index, 'currentTier', e.target.value)}
                                >
                                    <option value="">Select Current Tier</option>
                                    {data?.data?.map((tier) => (
                                        <option key={tier.membership_tier} value={tier.membership_tier}>
                                            {tier.membership_tier}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex flex-col space-y-2 text-sm">
                                <label>Target Tier</label>
                                <select
                                    className="p-2 border rounded-md bg-white dark:bg-moon-800 border-gray-300 dark:border-moon-700 text-gray-800 dark:text-moon-50"
                                    value={possibility.targetTier}
                                    onChange={(e) => handlePossibilityChange(index, 'targetTier', e.target.value)}
                                >
                                    <option value="">Select Target Tier</option>
                                    {data?.data?.map((tier) => (
                                        <option key={tier.membership_tier} value={tier.membership_tier}>
                                            {tier.membership_tier}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex flex-col space-y-2 text-sm">
                                <label>Members</label>
                                <input
                                    type="number"
                                    className="text-right p-2 border rounded-md bg-white dark:bg-moon-800 border-gray-300 dark:border-moon-700 text-gray-800 dark:text-moon-50"
                                    value={possibility.members}
                                    onChange={(e) => handlePossibilityChange(index, 'members', Number(e.target.value))}
                                />
                            </div>


                        </div>
                    ))}

                    <Button
                        size="small"
                        containerClass="w-full"
                        onClick={addPossibility}
                        startIcon={<AddIcon />}
                    >
                        {hasAddedPossibility ? 'Add One More Tier Conversion' : 'Add New Tier Conversion'}
                    </Button>

                    <h3 className="font-bold text-gray-900 dark:text-moon-50 text-base leading-7 mt-6">Add New Members</h3>
                    {newMembers.map((newMember, index) => (
                        <div key={index} className="drop-shadow-md bg-white dark:bg-moon-900 p-4 border rounded-md mb-4 space-y-2 border-gray-200 dark:border-moon-700">
                            <div className='flex justify-between items-center pb-4 border-b border-gray-200 dark:border-moon-700'>
                                <div className='text-gray-500 dark:text-moon-200 font-medium text-base'>
                                    Member Addition {index + 1}
                                </div>
                                <div>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        startIcon={<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 12V17" stroke="#ff4d4d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M14 12V17" stroke="#ff4d4d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M4 7H20" stroke="#ff4d4d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#ff4d4d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#ff4d4d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>}
                                        onClick={() => removeNewMember(index)}
                                    >
                                    </Button>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-2 text-sm">
                                <label>Tier</label>
                                <select
                                    className="p-2 border rounded-md bg-white dark:bg-moon-800 border-gray-300 dark:border-moon-700 text-gray-800 dark:text-moon-50"
                                    value={newMember.tier}
                                    onChange={(e) => handleNewMemberChange(index, 'tier', e.target.value)}
                                >
                                    <option value="">Select Tier</option>
                                    {data?.data?.map((tier) => (
                                        <option key={tier.membership_tier} value={tier.membership_tier}>
                                            {tier.membership_tier}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex flex-col space-y-2 text-sm">
                                <label>Members</label>
                                <input
                                    type="number"
                                    className="text-right p-2 border rounded-md bg-white dark:bg-moon-800 border-gray-300 dark:border-moon-700 text-gray-800 dark:text-moon-50"
                                    value={newMember.members}
                                    onChange={(e) => handleNewMemberChange(index, 'members', Number(e.target.value))}
                                />
                            </div>

                        </div>
                    ))}

                    <Button
                        size="small"
                        containerClass="w-full"
                        startIcon={<AddIcon />}
                        onClick={addNewMember}
                    >
                        {hasAddedNewMember ? 'Add Few More Members' : 'Add New Member'}
                    </Button>
                </div>

                {/* Right section: Points Summary */}
                <div className="mt-4 md:mt-0 md:w-1/3 p-4 pt-8 rounded-md drop-shadow-md bg-white dark:bg-moon-900 text-gray-800 dark:text-moon-50">
                    <h3 className="font-bold text-gray-900 dark:text-moon-50 text-lg leading-7">Points Summary</h3>
                    <div className="space-y-2 text-lg mt-4">
                        <div className="flex justify-between ">
                            <span className='text-gray-500 dark:text-moon-200 font-medium text-base'>Current Points:</span>
                            <span className='text-gray-900 shrink-0 dark:text-moon-50 font-semibold text-base'>
                                <NumberSpinner digitsCount={4.2} actualNumber={currentPoints} />
                            </span>
                        </div>
                        <div className="flex justify-between ">
                            <span className='text-gray-500 dark:text-moon-200 font-medium text-base'>Total Impact Points:</span>
                            <span className='text-gray-900 shrink-0 dark:text-moon-50 font-semibold text-base'>
                                <NumberSpinner digitsCount={2.2} actualNumber={totalImpact} />
                            </span>
                        </div>
                        <div className="flex justify-between ">
                            <span className='text-gray-500 dark:text-moon-200 font-medium text-base'>Final Projected Points:</span>
                            <span className='text-gray-900 shrink-0 dark:text-moon-50 font-semibold text-base'>
                                <NumberSpinner digitsCount={4.2} actualNumber={finalProjectedPoints} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MembershipCalculator;