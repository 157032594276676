import { useEffect, useRef, useState } from 'react';

const InfiniteStripe = ({ items, renderItem }) => {
    const scrollRef = useRef(null);
    const [isPaused, setIsPaused] = useState(false);
    const [position, setPosition] = useState(0);

    useEffect(() => {
        const scrollContainer = scrollRef.current;

        const handleScroll = () => {
            if (!isPaused) {
                setPosition((prev) => {
                    const newPosition = prev - 0.5; // Adjust speed by changing this value
                    // Reset position if scrolled past half of the original items
                    if (Math.abs(newPosition) >= scrollContainer.scrollWidth / 2) {
                        return 0; // Reset to the start
                    }
                    return newPosition;
                });
            }
        };

        const interval = setInterval(handleScroll, 16); // Approximately 60 FPS
        return () => clearInterval(interval);
    }, [isPaused]);

    const togglePauseResume = () => {
        setIsPaused((prev) => !prev);
    };

    // Duplicate the items for infinite effect
    const duplicatedItems = [...items, ...items];

    return (
        <div className='flex justify-between'>
            <div className='flex justify-between items-center w-full overflow-hidden'>
                <div
                    ref={scrollRef}
                    className='flex gap-8'
                    style={{
                        transform: `translateX(${position}px)`,
                        transition: 'transform 0.1s linear', // Smoother transition
                        whiteSpace: 'nowrap', // Prevent wrapping
                    }}
                    onMouseEnter={() => setIsPaused(true)}  // Pause on hover
                    onMouseLeave={() => setIsPaused(false)} // Resume on mouse leave
                >
                    {duplicatedItems.map((item, index) => (
                        <div key={index} className="flex-shrink-0">
                            {renderItem(item)}
                        </div>
                    ))}
                </div>

            </div>
            <div className='p-2 text-xs border-l border-gray-200 dark:border-moon-700'>
                <button
                    className={`transition ease-in duration-300`}
                    onClick={togglePauseResume}
                >
                    {isPaused ? <svg className='transition-transform duration-500' xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 9 11" fill="none">
                        <path d="M0 10.6923V0.307678L8.15385 5.5L0 10.6923ZM1 8.85L6.28845 5.5L1 2.15V8.85Z" fill="#515151" />
                    </svg> : <svg className='transition-transform duration-500' xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 13" fill="none">
                        <path d="M7.5 12.5V0.5H12V12.5H7.5ZM0 12.5V0.5H4.5V12.5H0ZM8.5 11.5H11V1.5H8.5V11.5ZM1 11.5H3.5V1.5H1V11.5Z" fill="#515151" />
                    </svg>}
                </button>
            </div>
        </div>
    );
};

export default InfiniteStripe;
