/* eslint-disable react-hooks/rules-of-hooks */
// Usage:
// For GET request
// const { data, error, isLoading } = useApi({
//     queryKey: ['data'],
//     url: '/api/data',
//     method: 'GET',
//     onSuccess: (data) => {
//       console.log('Data fetched successfully:', data);
//     },
//     onError: (error) => {
//       console.error('Error fetching data:', error);
//     },
//   });

//   // For POST request
//   const mutation = useApi({
//     queryKey: ['postData'],
//     url: '/api/data',
//     method: 'POST',
//     onSuccess: () => {
//       console.log('Data posted successfully');
//       // Invalidate and refetch the data after mutation
//       queryClient.invalidateQueries({ queryKey: ['data'] });
//     },
//     onError: (error) => {
//       console.error('Error posting data:', error);
//     },
//   });

//   const handleSubmit = () => {
//     mutation.mutate({
//       id: Date.now(),
//       title: 'Do Laundry',
//     });
//   };

// url: `${BASE_URL}${url}`,

import { useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import { BASE_URL } from '../../apiConstant';

const useApi = ({ queryKey, url, method = 'GET', onSuccess, onError, ...options }) => {
    const fetchData = async (data) => {
        const response = await axios({
            method,
            url: `${BASE_URL}${url}`,
            data,
        });
        return response.data;
    };

    const queryResult = method === 'GET' ? useQuery({
        queryKey,
        queryFn: fetchData,
        ...options,
        onSuccess,
        onError
    }) : useMutation({
        mutationFn: fetchData,
        ...options,
        onSuccess: onSuccess ? onSuccess : undefined,
        onError: onError ? onError : undefined,
    });

    useEffect(() => {
        if (queryResult.isSuccess && onSuccess) {
            onSuccess(queryResult.data);
        }
    }, [queryResult.data, queryResult.isSuccess, onSuccess, queryKey]);

    return queryResult;
};

// PropTypes for the options parameter
useApi.propTypes = {
    queryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    url: PropTypes.string.isRequired,
    method: PropTypes.oneOf(['GET', 'POST', 'PUT', 'DELETE']),
    onSuccess: PropTypes.func, // Optional
    onError: PropTypes.func,    // Optional
    options: PropTypes.object,
};

export default useApi;
