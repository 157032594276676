import { useParams } from 'react-router-dom';
import Gauge from '../../uikit/guageChart'
import { getCurrentDate } from '../../utils/dateFormatter';
import useApi from '../../utils/hooks/useApi';
import useQueryParam from '../../utils/hooks/useQueryParam';
import Abbreviation from '../../uikit/abbreviation';

const ClubTargetGauge = () => {
    const params = useParams();
    const [dateParam] = useQueryParam('date');
    const currentDate = dateParam || getCurrentDate();

    const { data: successStats, error, isLoading, refetch } = useApi({
        queryKey: ['successStats', currentDate, params?.clubName],
        url: `/eomsuccesspoints?club_name=${params?.clubName}&start_date=${currentDate}&end_date=${currentDate}&daily=true&order_direction=desc&paginate=true&page=1&page_size=10`,
        staleTime: 7200000, // 2 hours in milliseconds
        cacheTime: 7200000, // 2 hours in milliseconds
        method: 'GET',
        onSuccess: (data) => {
        },
        onError: (error) => {
            console.error('Error fetching data:', error);
        },
    });

    let statusLabel;
    if (successStats?.data?.[0]?.difference > 0) {
        statusLabel = "Above Target";
    } else if (successStats?.data?.[0]?.difference < 0) {
        statusLabel = "Shortfall";
    } else {
        statusLabel = "On Target";
    }

    return (
        <div className=" w-full container flex justify-center items-center flex-col max-w-[100vw] ring-gray-200 dark:ring-moon-700 ring-2 py-1.5 px-2 rounded-xl bg-white dark:bg-moon-900">
            <div className="">
                <Gauge digitsCount={5.2} width='w-[69px]' differenceLabel={statusLabel} difference={successStats?.data?.[0]?.difference || null} currentValue={successStats?.data?.[0]?.total_points || null} currentLabel='Current Success Point' targetLabel={<><Abbreviation title="End of Month">EOM</Abbreviation> <Abbreviation title="Success Point"> SP</Abbreviation>&apos;s Goal</>} targetValue={successStats?.data?.[0]?.max_eom_success_points || null} />
            </div>
        </div>
    )
}

export default ClubTargetGauge