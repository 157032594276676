import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const useQueryParam = (param) => {
    const location = useLocation();
    const navigate = useNavigate();

    // Get the current value of the query parameter
    const getQueryParam = () => {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    };

    // Set the query parameter to a new value
    const setQueryParam = useCallback(
        (value) => {
            const params = new URLSearchParams(location.search);
            if (value === null) {
                // Remove the parameter if the value is null
                params.delete(param);
            } else {
                params.set(param, value);
            }
            navigate(`?${params.toString()}`, { replace: true });
        },
        [location.search, navigate, param]
    );

    return [getQueryParam(), setQueryParam];
};


export default useQueryParam;
