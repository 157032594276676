import { useParams } from 'react-router-dom'
import GaugeChart from '../../uikit/guageChart'
import useQueryParam from '../../utils/hooks/useQueryParam'
import MemberGainers from './MemberGainers'
import MemberLosers from './MemberLosers'
import OverallMemberChart from './OverallMemberChart'
import TargetStats from './TargetStats'
import TotalCount from './TotalCount'
import { getCurrentDate } from '../../utils/dateFormatter'
import MemberChart from '../details/MemberChart'

const Stats = () => {
    const params = useParams();
    const [paramValue] = useQueryParam('date');
    return (
        <div className='flex flex-col md:flex-row gap-5 my-4 2lg:my-9'>
            <TotalCount />
            <TargetStats />

            <OverallMemberChart period="daily"
                startDate="2024-07-31"
                endDate={paramValue || getCurrentDate()} />
        </div>
    )
}

export default Stats