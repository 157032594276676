const SkeletonPage = () => {
  return (
    <>
      <div className="p-4 bg-gray-100 screen-minus-header">
        <div className="grid grid-cols-2 grid-rows-4 gap-4 md:grid-cols-4">
          {Array.from({ length: 20 }, (_, index) => (
            <div
              key={index}
              className="h-64 bg-gray-300 rounded animate-pulse"
            ></div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SkeletonPage;
