import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ButtonGroup = ({ buttons, defaultActive, onClick }) => {
    const [activeButton, setActiveButton] = useState(defaultActive);

    const handleClick = (key) => {
        setActiveButton(key);
        if (onClick) {
            onClick(key); // Pass the active key to the parent on click
        }
    };

    return (
        <div
            role="group"
            className="p-1 bg-gray-100 dark:bg-moon-800 rounded-lg w-fit isolate"
        >
            {buttons.map(({ id, label, key }) => (
                <button
                    key={key}
                    id={id}
                    type="button"
                    className={`px-3 py-1 rounded-lg text-gray-700 dark:text-moon-100 font-semibold text-sm leading-5 
            ${activeButton === key ? 'bg-white dark:bg-moon-900 shadow-md !text-black dark:!text-moon-100' : 'bg-transparent'} 
             dark:hover:bg-moon-700`}
                    onClick={() => handleClick(key)}
                >
                    <span className="text-inherit">
                        {label}
                    </span>
                </button>
            ))}
        </div>
    );
};

// Prop Types
ButtonGroup.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
        })
    ).isRequired,
    defaultActive: PropTypes.string,
    onClick: PropTypes.func,
};

ButtonGroup.defaultProps = {
    defaultActive: '',
    onClick: null,
};

export default ButtonGroup;
