import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { createChart } from 'lightweight-charts';
import PropTypes from 'prop-types';
import { BASE_URL } from '../../apiConstant';
import ButtonGroup from './ButtonGroup';
import ClubTable from './ClubTable';
import { useGlobal } from '../../utils/contexts/GlobalContext';

// Fetch members data function
const fetchMembersData = async (pageParam = 1, period, clubName, startDate, endDate, pageSize) => {
    const response = await fetch(
        `${BASE_URL}/public-members?period=${period}&order_by_date=desc&club_name=${clubName}&startDate=${startDate}&endDate=${endDate}&page_size=${pageSize}&page=${pageParam}`
    );
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return await response.json();
};

// Create initial placeholder data for the last 10 days
const createInitialPlaceholderData = () => {
    return [
        {
            "time": 1726617600,
            "value": 0
        },
        {
            "time": 1726704000,
            "value": 0
        }
    ]
};

const MemberChart = ({ period, clubName, startDate, endDate }) => {
    const [selectedMetric, setSelectedMetric] = useState('movement_in_members');
    const [pageSize, setPageSize] = useState(10); // State to keep track of the selected page size
    const { state } = useGlobal(); // Get global state and dispatch
    const isDarkMode = state.isDarkMode; // Access dark mode state from global context
    const chartContainerRef = useRef();
    const chartRef = useRef(null);
    const seriesRef = useRef(null);
    const debounceTimerRef = useRef(null);
    const lastFetchedTimeRef = useRef(null);
    const previousVisibleRangeRef = useRef(null);

    const {
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        data,
        isLoading,
        isError,
        error,
    } = useInfiniteQuery({
        queryKey: ['membersData', { period, clubName, startDate, endDate, pageSize }],
        queryFn: ({ pageParam = 1 }) => fetchMembersData(pageParam, period, clubName, startDate, endDate, pageSize),
        getNextPageParam: (lastPage) => (lastPage.pagination.hasNextPage ? lastPage.pagination.currentPage + 1 : undefined),
        enabled: true,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    });

    // const chartData = React.useMemo(() => {
    //     if (!data) return [];
    //     return data.pages.flatMap(page =>
    //         page.data.map(item => ({
    //             time: new Date(item.name).getTime() / 1000,
    //             value: Number(item[selectedMetric]),
    //         }))
    //     ).sort((a, b) => a.time - b.time);
    // }, [data, selectedMetric]);

    const chartData = React.useMemo(() => {
        if (!data) return [];
        return data.pages.flatMap(page =>
            page.data.map(item => {
                const [year, month, day] = item.name.split('-').map(Number);
                const time = Date.UTC(year, month - 1, day) / 1000; // Convert to Unix timestamp in seconds
                return {
                    time,
                    value: Number(item[selectedMetric]),
                };
            })
        ).sort((a, b) => a.time - b.time);
    }, [data, selectedMetric]);

    const initialData = React.useMemo(() => createInitialPlaceholderData(), []);

    const handleVisibleRangeChange = useCallback((logicalRange) => {
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
            const currentTime = Date.now();
            const timeSinceLastFetch = currentTime - (lastFetchedTimeRef.current || 0);

            if (hasNextPage && !isFetchingNextPage && timeSinceLastFetch > 2000) {
                const visibleRange = chartRef.current.timeScale().getVisibleRange();
                const dataRange = chartRef.current.timeScale().getVisibleLogicalRange();

                if (visibleRange && dataRange) {
                    // Check if user has scrolled left
                    if (previousVisibleRangeRef.current) {
                        const isScrollingLeft = logicalRange.from < previousVisibleRangeRef.current.from;

                        if (isScrollingLeft) {
                            const visibleDataPercentage = (dataRange.to - dataRange.from) / (visibleRange.to - visibleRange.from);

                            if (visibleDataPercentage < 0.7) {
                                console.log('Fetching more data');
                                lastFetchedTimeRef.current = currentTime;
                                fetchNextPage();
                            }
                        }
                    }

                    // Update the previous visible range
                    previousVisibleRangeRef.current = logicalRange;
                }
            }
        }, 200);
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    useEffect(() => {
        if (!chartContainerRef.current) return;

        const chartOptions = {
            layout: {
                textColor: !isDarkMode ? '#0d1217' : '#dfe5ec',
                background: {
                    color: isDarkMode ? '#0d1217' : 'white',
                    text: !isDarkMode ? '#0d1217' : '#dfe5ec',
                },
            },
        };

        if (!chartRef.current) {
            chartRef.current = createChart(chartContainerRef.current, {
                ...chartOptions,
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight,
                watermark: {
                    visible: false, // Ensures the watermark is hidden
                },
            });

            seriesRef.current = chartRef.current.addBaselineSeries({
                baseValue: { type: 'price', price: 0 },
                topLineColor: 'rgba(38, 166, 154, 1)',
                topFillColor1: 'rgba(38, 166, 154, 0.28)',
                topFillColor2: 'rgba(38, 166, 154, 0.05)',
                bottomLineColor: 'rgba(239, 83, 80, 1)',
                bottomFillColor1: 'rgba(239, 83, 80, 0.05)',
                bottomFillColor2: 'rgba(239, 83, 80, 0.28)',
            });

            chartRef.current.timeScale().subscribeVisibleLogicalRangeChange(handleVisibleRangeChange);
        }

        // Use initial placeholder data before real data comes in
        seriesRef.current.setData(initialData);

        // Once data is available, update the chart
        if (chartData.length > 0) {
            seriesRef.current.setData(chartData);
            chartRef.current.timeScale().fitContent();
        }

        const removeLogo = () => {
            const logo = document.getElementById('tv-attr-logo');
            if (logo) {
                logo.style.display = 'none'; // Hide the logo
            }
        };
        // Call after a small delay to ensure the chart is rendered
        const timer = setTimeout(removeLogo, 0);

        return () => {
            if (chartRef.current) {
                chartRef.current.remove();
                chartRef.current = null;
                seriesRef.current = null;
            }
            clearTimeout(timer);
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
        };
    }, [chartData, initialData, handleVisibleRangeChange, isDarkMode]);

    const handleMetricChange = (metric) => {
        setSelectedMetric(metric);
    };

    const handleIntervalChange = (interval) => {
        setPageSize(parseInt(interval, 10)); // Update the page size state
    };

    const buttons = [
        { id: 'movement_in_members', label: 'Members', key: 'movement_in_members' },
        { id: 'movement_in_points', label: 'Success Points', key: 'movement_in_points' },
    ];

    const pageSizeButtons = [
        { id: '10', label: '10D', key: '10' },
        { id: '20', label: '20D', key: '20' },
        { id: '30', label: '30D', key: '30' },
    ];

    return (
        <>
            <div className="md:p-4 relative">
                <div className='flex flex-col md:flex-row justify-between items-center gap-2'>
                    <ButtonGroup buttons={buttons} defaultActive="movement_in_members" onClick={handleMetricChange} />
                    <ButtonGroup buttons={pageSizeButtons} defaultActive="10" onClick={handleIntervalChange} />
                </div>

                <div className='mt-2' ref={chartContainerRef} style={{ position: 'relative', width: '100%', height: '300px' }} />

                {isFetchingNextPage && (
                    <div className="mt-4 flex justify-center">
                        <span className='loader'></span>
                    </div>
                )}
            </div>
            <div className='md:p-4'>
                <ClubTable data={data?.pages?.[0]?.data} />
            </div>
        </>
    );
};

MemberChart.propTypes = {
    period: PropTypes.string.isRequired,
    clubName: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
};

export default MemberChart;