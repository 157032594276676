import { useMemo } from "react";
import SimpleClientTable from "../../uikit/simple-client-table"
import { formatNumberWithThousandsSeparator, formatNumberWithThousandsSeparatorWithDecimal, getColorCode, roundToTwoDecimals } from "../../utils/commonUtils";
import ArrowIcon from "../../common/ImageJSX/ArrowIcon";
import Abbreviation from "../../uikit/abbreviation";
import { numericSortingFn } from "../../uikit/simple-client-table/tableUtils";

const ClubTable = ({ data }) => {
    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Date',
                minSize: '500',
                id: 'name',
                cell: (row) => (
                    <>
                        <div className="flex items-center gap-1 shrink-0 min-w-[120px]">
                            <div>
                                <ArrowIcon direction={row?.row?.original?.['movement_in_members'] < 0 ? 'down' : 'up'} color={getColorCode(row?.row?.original?.['movement_in_members'])} />
                            </div>
                            <div className=' leading-4 text-gray-500'>
                                {row?.row?.original?.['name']}
                            </div>
                            <div className="text-black">

                            </div>
                        </div>
                    </>
                ),
            },
            {
                accessorKey: 'total_points',
                header: 'Total Success Points',
                id: 'totalPoints',
                alignment: 'center',
                sortingFn: numericSortingFn,
                cell: ({ cell }) => formatNumberWithThousandsSeparatorWithDecimal(cell.getValue()),
            },
            {
                accessorKey: 'movement_in_points',
                header: <span>Monthly Movement in <Abbreviation title="Success Point">SP</Abbreviation></span>,
                id: 'movementInPoints',
                alignment: 'center',
                sortingFn: numericSortingFn,
                cell: ({ cell }) => formatNumberWithThousandsSeparatorWithDecimal(cell.getValue()),
            },
            {
                accessorKey: 'points_to_target',
                header: <span><Abbreviation title="Success Point">SP</Abbreviation>&apos;s To Goal</span>,
                id: 'pointsToTarget',
                alignment: 'center',
                sortingFn: numericSortingFn,
                cell: ({ cell }) => formatNumberWithThousandsSeparatorWithDecimal(cell.getValue()),
            },
            {
                accessorKey: 'eom_success_points',
                header: <div><Abbreviation title="End of Month">EOM </Abbreviation> <Abbreviation title="Success Point"> SP</Abbreviation>&apos;s Goal</div>,
                id: 'eomSuccessPoints',
                alignment: 'center',
                sortingFn: numericSortingFn,
                cell: ({ cell }) => formatNumberWithThousandsSeparatorWithDecimal(cell.getValue()),
            },
            {
                accessorKey: 'fitnesspassportvisitors',
                header: 'Fitness Passport Visitors',
                id: 'fitnesspassportvisitors',
                sortingFn: numericSortingFn,
                alignment: 'center',
                cell: ({ cell }) => formatNumberWithThousandsSeparator(cell.getValue()),
            },
        ],
        []
    );
    return (<>
        <SimpleClientTable alignment='center' thClassName={`!font-semibold !text-xs !text-gray-900 dark:!text-moon-50 !whitespace-nowrap first:text-left pl-2 !bg-gray-100 dark:!bg-moon-800 !border-b !border-gray-200 dark:!border-moon-700`} columns={columns} data={data || []} />
    </>)
}

export default ClubTable