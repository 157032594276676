import GaugeChart from "react-gauge-chart";
import { useGlobal } from "../../utils/contexts/GlobalContext";
import NumberSpinner from "../numberSpinner";

const GaugeWrapper = ({ targetValue, width = 'w-[55px]', differenceLabel = '', difference = 0, targetLabel = 'Target Value:', currentLabel = 'Current Value:', digitsCount = 5, currentValue, className = "" }) => {
  // Calculate percent based on currentValue and targetValue
  const percent = Math.min(currentValue / targetValue, 1); // Cap at 1 for cases exceeding target
  const actualPercent = (currentValue / targetValue) * 100; // Calculate actual percent
  const { state } = useGlobal(); // Get global state and dispatch
  const isDarkMode = state.isDarkMode;

  return (
    <div className={`w-full max-w-md mx-auto flex flex-col py-2 items-center ${className}`}>
      <GaugeChart
        id="gauge-chart5"
        nrOfLevels={420}
        arcsLength={[0.3, 0.5, 0.2]}
        colors={["#EA4228", "#F5CD19", "#5BE12C"]}
        percent={percent || 0}
        textColor={isDarkMode ? "#ffffff" : "#000000"}
        arcPadding={0.02}
        style={{ width: "100%", maxWidth: "200px", color: "#1a202c" }}
        formatTextValue={() => actualPercent ? `${actualPercent.toFixed(2)}%` : ''} // Custom text format
      />
      <div className="mt-2 text-gray-900 dark:text-moon-50 font-semibold text-base leading-6 flex justify-center flex-col">
        <div className="flex">
          <span className="text-gray-900 dark:text-moon-50 font-semibold text-sm leading-6 mr-1">{currentLabel}</span> : <div className={`flex ml-1 ${width}`}><NumberSpinner digitsCount={digitsCount} actualNumber={currentValue} /></div>
        </div>
        <div className="flex">
          <span className="text-gray-900 dark:text-moon-50 font-semibold text-sm leading-6 mr-1">{targetLabel}</span> : <div className={`flex ml-1 ${width}`}><NumberSpinner digitsCount={digitsCount} actualNumber={targetValue} /></div>
        </div>
        <div className="flex">
          <span className="text-gray-900 dark:text-moon-50 font-semibold text-sm leading-6 mr-1">{differenceLabel}</span> : <div className={`flex ml-1 ${width}`}><NumberSpinner digitsCount={digitsCount} actualNumber={difference} /></div>
        </div>
      </div>
    </div>
  );
};

export default GaugeWrapper;