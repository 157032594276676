// SemiCircleDonutChart.js
import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';
import SemiCircleDonutLegend from './SemiCircleDonutLegend';
import { applyColors } from './applyColors';
import { formatNumberWithThousandsSeparator, formatNumberWithThousandsSeparatorWithDecimal, roundToTwoDecimals } from '../../utils/commonUtils';

const SemiCircleDonutChart = ({
    data = [],
    leftMarker = '',
    rightMarker = '',
    elevate = false,
    borderRadius = 'rounded-lg',
    chartHeight = '150px',
    chartWidth = '100%',
    margin = { top: 0, right: 10, bottom: 0, left: 10 },
}) => {
    const coloredData = applyColors(data);
    const totalValue = data.reduce((acc, item) => acc + item.value, 0);

    return (
        <div className={`ring-gray-200 dark:ring-moon-700 ring-2 p-2 md:p-4  ${borderRadius} ${elevate ? 'shadow-md' : ''}`}>
            <div className="flex justify-between mb-1">
                <div className="flex flex-col items-center text-sm font-semibold text-gray-500 dark:text-moon-200">
                    {leftMarker}
                </div>
                <div className="flex flex-col items-center ">
                    <div className="font-semibold text-gray-900 dark:text-moon-50">{formatNumberWithThousandsSeparatorWithDecimal(totalValue)}</div>
                    <div className="text-xs text-gray-500 dark:text-moon-200">{rightMarker}</div>
                </div>
            </div>
            <div
                className="relative "
                style={{ height: chartHeight, width: chartWidth }}
            >
                <ResponsivePie
                    data={coloredData}
                    margin={margin}
                    startAngle={-90}
                    endAngle={90}
                    innerRadius={0.6}
                    padAngle={1}
                    cornerRadius={20}
                    activeOuterRadiusOffset={8}
                    borderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.2]],
                    }}
                    colors={{ datum: 'data.color' }}
                    enableArcLinkLabels={false}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextOffset={7}
                    arcLinkLabelsTextColor="#1F2B4D"
                    arcLinkLabelsColor={{ from: 'color' }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor="#1F2B4D"
                />
            </div>
            <SemiCircleDonutLegend data={coloredData} />
        </div>
    );
};

SemiCircleDonutChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        })
    ),
    leftMarker: PropTypes.string,
    rightMarker: PropTypes.string,
    elevate: PropTypes.bool,
    borderRadius: PropTypes.string,
    chartHeight: PropTypes.string,
    chartWidth: PropTypes.string,
    margin: PropTypes.object,
};

export default SemiCircleDonutChart;
