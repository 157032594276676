import { useQueryClient } from "@tanstack/react-query";
import useApi from "../../utils/hooks/useApi";
import { getCurrentDate } from "../../utils/dateFormatter";
import { getTopGainersAndLosers } from "../../utils/dataAlteration";
import useQueryParam from "../../utils/hooks/useQueryParam";
import { useEffect } from "react";



function GlobalCode() {
  const queryClient = useQueryClient();
  const [dateParam] = useQueryParam('date');
  const currentDate = dateParam || getCurrentDate();

  const { data, error, isLoading, refetch } = useApi({
    queryKey: ['allMemberMergedData', currentDate],
    url: `/public-members?period=daily&startDate=${currentDate}&endDate=${currentDate}&paginate=false`,
    staleTime: 7200000, // 2 hours in milliseconds
    cacheTime: 7200000, // 2 hours in milliseconds
    method: 'GET',
    onSuccess: (data) => {
      const gainersAndLosers = getTopGainersAndLosers(data?.data || []);
      queryClient.setQueryData(['allMemberGainerLooserData', currentDate], gainersAndLosers);
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    },
  });

  useEffect(() => {
    // Refetch data when dateParam changes
    if (dateParam) {
      refetch();
    }
  }, [dateParam, refetch]);

  return <div></div>;
}


export default GlobalCode;
