import { useQuery } from "@tanstack/react-query";
import { getCurrentDate } from "../../utils/dateFormatter";
import SimpleClientTable from "../../uikit/simple-client-table";
import { useMemo } from "react";
import { FAKE_MERGED_DATA } from "../../utils/dummyAPIResponse";
import ArrowIcon from "../../common/ImageJSX/ArrowIcon";
import { formatNumberWithThousandsSeparator, formatNumberWithThousandsSeparatorWithDecimal, getColorCode, roundToTwoDecimals } from "../../utils/commonUtils";
import useQueryParam from "../../utils/hooks/useQueryParam";
import { BASE_URL } from "../../apiConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { getTopGainersAndLosers } from "../../utils/dataAlteration";
import LinearGauge from "../details/LinearGauge";
import Abbreviation from "../../uikit/abbreviation";
import { numericSortingFn } from "../../uikit/simple-client-table/tableUtils";


const DayTable = () => {
    const [dateParam] = useQueryParam('date');
    const navigate = useNavigate();
    const location = useLocation();
    const currentDate = dateParam || getCurrentDate()
    const { data: mergedData = [], isLoading } = useQuery({
        queryKey: ['allMemberMergedData', currentDate],
        initialData: () => FAKE_MERGED_DATA,
    });

    const fetchTrendData = async (clubName) => {
        const response = await fetch(
            `${BASE_URL}/public-members?period=daily&order_by_date=desc&club_name=${clubName}&startDate=2024-07-31&endDate=${currentDate}&page_size=10&page=1`
        );
        return response.json();
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'club_name',
                header: 'Club Names',
                id: 'name',
                sticky: true,
                cell: (row) => (
                    <>
                        <div className="flex items-center gap-1">
                            <div>
                                <ArrowIcon direction={row?.row?.original?.['movement_in_members'] < 0 ? 'down' : 'up'} color={getColorCode(row?.row?.original?.['movement_in_members'])} />
                            </div>
                            <div>
                                <img src={row?.row?.original?.image_url} className='w-6 h-6 ' width={24} height={24} alt={row?.row?.original?.['club_name']} />
                            </div>
                            <div className='text-xs leading-4 text-gray-500 dark:text-moon-200'>
                                {row?.row?.original?.['club_name']}
                            </div>
                            <div className="text-black">

                            </div>
                        </div>
                    </>
                ),
            },
            {
                accessorKey: 'points_to_target',
                header: 'Ranking',
                id: 'pointsToTarget',
                sortingFn: numericSortingFn,
                cell: ({ cell }) => {
                    const { clubRanking } = getTopGainersAndLosers(mergedData?.data, cell.row.original?.club_name) || {};
                    return (
                        <div key={`${clubRanking}_${cell.getValue()}`} className="flex justify-center">
                            <div
                                data-view-component="true"
                                className="mt-0.5 ml-1 mr-3 2lg:mr-0 inline-flex items-center px-1.5 py-0.5 bg-gray-100 dark:bg-moon-400/20 rounded-md"
                            >
                                <div
                                    data-view-component="true"
                                    className="text-xs leading-4 text-gray-700 dark:text-moon-200 font-medium"
                                >
                                    #{clubRanking ?? '-'}
                                </div>
                            </div>
                        </div>
                    );
                },
            },

            // {
            //     accessorKey: 'member_count',
            //     header: 'Members Count',
            //     alignment: 'center',
            //     id: 'memberCount',
            //     cell: ({ cell }) => formatNumberWithThousandsSeparator(cell.getValue()),
            // },
            // {
            //     accessorKey: 'movement_in_members',
            //     header: 'Movement In Members',
            //     alignment: 'center',
            //     id: 'movementInMembers',
            //     cell: ({ cell }) => formatNumberWithThousandsSeparator(cell.getValue()),

            // },
            {
                accessorKey: 'total_points',
                header: 'Total Success Points',
                id: 'totalPoints',
                sortingFn: numericSortingFn,
                alignment: 'center',
                cell: ({ cell }) => formatNumberWithThousandsSeparatorWithDecimal(cell.getValue()),
            },
            {
                accessorKey: 'movement_in_points',
                header: <span>Monthly Movement in <Abbreviation title="Success Point">SP</Abbreviation></span>,
                id: 'movementInPoints',
                sortingFn: numericSortingFn,
                alignment: 'center',
                cell: ({ cell }) => formatNumberWithThousandsSeparatorWithDecimal(cell.getValue()),
            },
            {
                accessorKey: 'points_to_target',
                header: () => (
                    <span>
                        <Abbreviation title="Success Point">SP</Abbreviation>s To Goal
                    </span>
                ),
                cell: ({ cell }) => (
                    <div style={{ textAlign: 'center' }}>
                        {formatNumberWithThousandsSeparatorWithDecimal(cell.getValue())}
                    </div>
                ),
                sortingFn: numericSortingFn,
            },
            {
                accessorKey: 'eom_success_points',
                header: <div><Abbreviation title="End of Month">EOM </Abbreviation> <Abbreviation title="Success Point">SP</Abbreviation>&apos;s Goal</div>,
                id: 'eomSuccessPoints',
                alignment: 'center',
                sortingFn: numericSortingFn,
                cell: ({ cell }) => formatNumberWithThousandsSeparatorWithDecimal(cell.getValue()),
            },
            {
                accessorKey: 'points_to_target',
                header: 'Goal',
                alignment: 'center',
                id: 'target',
                sortingFn: numericSortingFn,
                cell: ({ cell }) => {
                    const { eom_success_points, total_points } = cell?.row?.original
                    return <div key={`target_${total_points}_${eom_success_points}`}>
                        <LinearGauge width="200px" height='1.2rem' start={0} target={eom_success_points} current={total_points} label="Goal" />
                    </div>
                },
            },
            // {
            //     accessorKey: 'movement_percentage_points',
            //     header: 'Last 10 Days',
            //     id: 'movementInMembersTrend',
            //     width: 100,
            //     maxWidth: 100,
            //     sortable: false,
            //     cell: ({ row }) => {
            //         const { club_name, name } = row.original; // Extract parameters from the row

            //         // Use useQuery to fetch trend data for the current row
            //         // eslint-disable-next-line react-hooks/rules-of-hooks
            //         const { data: trendData, isLoading: trendLoading } = useQuery({
            //             queryKey: ['trendData', club_name, name, currentDate], // Only fetch for the current row
            //             queryFn: () => fetchTrendData(club_name, name), // Fetch data for the trend line
            //             enabled: !!club_name && !!name, // Only run if parameters are available
            //             refetchOnWindowFocus: false,
            //             staleTime: Infinity,
            //         });

            //         if (trendLoading) {
            //             return <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</div>;
            //         }

            //         if (!trendData) {
            //             return <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No data</div>;
            //         }

            //         const formattedTrendData = trendData.data
            //             .map((item) => ({
            //                 time: item.name, // Use name as time
            //                 value: item.movement_percentage_points, // Use movement_percentage_points as value
            //             }))
            //             .sort((a, b) => new Date(a.time) - new Date(b.time)); // Sort by time in ascending order

            //         // Render the trend line with the formatted trend data
            //         return <div style={{ width: '100%', minWidth: '100px', padding: '0 0px' }}>
            //             <TrendLine data={formattedTrendData} />
            //         </div>;
            //     },
            // },
        ],
        [currentDate, mergedData]
    );

    const handleRowClick = (row) => {
        navigate(`/${row?.original?.club_name}${location.search}`);
    }

    return (
        <div className="ring-gray-200 dark:ring-moon-700 ring-2 py-1.5 px-2 rounded-xl bg-white dark:bg-moon-900">
            <SimpleClientTable isPaginationRequired={false} columns={columns} pageSize={20} data={mergedData?.data || []} handleRowClick={handleRowClick} />
        </div>
    )
}

export default DayTable