import { Outlet } from 'react-router-dom';
import Header from './modules/header/Header';
import Toaster from './uikit/toaster';
import { GlobalProvider } from './utils/contexts/GlobalContext';
import GlobalCode from './modules/globalcode';
import Footer from './modules/footer';

export default function Layout() {
  return (
    <>
      <div className="flex flex-col min-h-screen bg-white dark:bg-moon-900">
        <GlobalProvider>
          <main className="flex-grow">
            <Header />
            <GlobalCode />
            <div className=" bg-white dark:bg-moon-900">
              <Outlet />
            </div>
          </main>
          <Footer />
        </GlobalProvider>
      </div>

      <Toaster />
    </>
  );
}
