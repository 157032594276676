export function mergeDataByDateAndClub(data) {
    const mergedData = {};
  
    data.forEach((entry) => {
      const key = `${entry.Date}-${entry['club_name']}`;
  
      if (!mergedData[key]) {
        // Initialize the merged entry
        mergedData[key] = {
          ...entry,
          membership_tier: new Set([entry.membership_tier]),
          'Membership Tier': new Set([entry['Membership Tier']])
        };
      } else {
        // Sum the numeric fields
        mergedData[key]['member_count'] += entry['member_count'];
        mergedData[key]['Multiplier'] += entry['Multiplier'];
        mergedData[key]['total_points'] += entry['total_points'];
        mergedData[key]['Previous Members'] += entry['Previous Members'];
        mergedData[key]['movement_in_members'] += entry['movement_in_members'];
        mergedData[key]['Previous Success Points'] += entry['Previous Success Points'];
        mergedData[key]['movement_in_points'] += entry['movement_in_points'];
  
        // Add to the Set for membership_tier fields
        mergedData[key].membership_tier.add(entry.membership_tier);
        mergedData[key]['Membership Tier'].add(entry['Membership Tier']);
      }
    });
  
    // Convert Sets back to arrays
    Object.values(mergedData).forEach((entry) => {
      entry.membership_tier = Array.from(entry.membership_tier);
      entry['Membership Tier'] = Array.from(entry['Membership Tier']);
    });
  
    return Object.values(mergedData);
  }

  // export function getTopGainersAndLosers(data) {
  //   // Filter and sort by "movement_in_members" (Gainers: positive values, Losers: negative values)
  //   const memberGainers = data.filter(item => item["movement_in_members"] > 0).sort((a, b) => b["movement_in_members"] - a["movement_in_members"]);
  //   const memberLosers = data.filter(item => item["movement_in_members"] ).sort((a, b) => a["movement_in_members"] - b["movement_in_members"]);
  
  //   // Top 3 gainers and losers in members
  //   const topMemberGainers = memberGainers.slice(0, 3);
  //   const topMemberLosers = memberLosers.slice(0, 3);
  
  //   // Filter and sort by "movement_in_points" (Gainers: positive values, Losers: negative values)
  //   const pointGainers = data.filter(item => item["movement_in_points"] > 0).sort((a, b) => b["movement_in_points"] - a["movement_in_points"]);
  //   const pointLosers = data.filter(item => item["movement_in_points"]).sort((a, b) => a["movement_in_points"] - b["movement_in_points"]);
  
  //   // Top 3 gainers and losers in points
  //   const topPointGainers = pointGainers.slice(0, 3);
  //   const topPointLosers = pointLosers.slice(0, 3);
  
  //   return {
  //     topMemberGainers,
  //     topMemberLosers,
  //     topPointGainers,
  //     topPointLosers,
  //   };
  // }
  
  export function getTopGainersAndLosers(data, clubName) {
    // Check if data is an array; if not, return default values
    if (!Array.isArray(data)) {
        return {
            topMemberGainers: [],
            topMemberLosers: [],
            topPointGainers: [],
            topPointLosers: [],
            clubRanking: null,
        };
    }

    // Filter and sort by "movement_in_members" (Gainers: positive values, Losers: negative values)
    const memberGainers = data.filter(item => item["movement_in_members"] > 0)
        .sort((a, b) => b["movement_in_members"] - a["movement_in_members"]);
    const memberLosers = data.filter(item => item["movement_in_members"] < 0)
        .sort((a, b) => a["movement_in_members"] - b["movement_in_members"]);

    // Top 3 gainers and losers in members
    const topMemberGainers = memberGainers.slice(0, 3);
    const topMemberLosers = memberLosers.slice(0, 3);

    // Filter and sort by "movement_in_points" (Gainers: positive values, Losers: negative values)
    const pointGainers = data.filter(item => item["points_to_target"] > 0)
        .sort((a, b) => b["points_to_target"] - a["points_to_target"]);
    const pointLosers = data.filter(item => item["points_to_target"] < 0)
        .sort((a, b) => a["points_to_target"] - b["points_to_target"]);

    // Top 3 gainers and losers in points
    const topPointGainers = pointGainers.slice(0, 3);
    const pointGainersFull = pointGainers.slice(0, 3);
    const topPointLosers = pointLosers.slice(0, 3);

    // Initialize clubRanking as null
    let clubRanking = null;
    const sortedPoints = data;
    // If a clubName is provided, find its ranking
    if (clubName) {
        sortedPoints.sort((a, b) => b["points_to_target"] - a["points_to_target"]);

        clubRanking = sortedPoints.findIndex(item => item.club_name && item.club_name.toLowerCase() === clubName.toLowerCase()) + 1;
        clubRanking = clubRanking > 0 ? clubRanking : null; // Return null if not found
    }
    return {
        topMemberGainers,
        topMemberLosers,
        topPointGainers,
        pointGainersFull,
        topPointLosers,
        sortedPoints,
        clubRanking, // Will be null if clubName is not provided
    };
}

