/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useRef } from 'react';
import PropTypes from 'prop-types';

const ToolTip = ({
  children,
  tooltipText,
  orientation = 'top',
  toolTipContainerClass,
}) => {
  const tipRef = useRef(null);

  const orientations = {
    right: 'right',
    top: 'top',
    left: 'left',
    bottom: 'bottom',
  };

  function handleMouseEnter() {
    tipRef.current.style.opacity = 1;
  }

  function handleMouseLeave() {
    tipRef.current.style.opacity = 0;
  }

  const setContainerPosition = (orientation) => {
    let classnames;

    switch (orientation) {
      case orientations.right:
        classnames = 'top-0 left-full ml-4';
        break;
      case orientations.left:
        classnames = 'top-0 right-full mr-4';
        break;
      case orientations.top:
        classnames = 'bottom-full left-[50%] translate-x-[-50%] -translate-y-2';
        break;
      case orientations.bottom:
        classnames = 'top-full left-[50%] translate-x-[-50%] translate-y-2';
        break;

      default:
        break;
    }

    return classnames;
  };

  const setPointerPosition = (orientation) => {
    let classnames;

    switch (orientation) {
      case orientations.right:
        classnames = 'left-[-6px]';
        break;
      case orientations.left:
        classnames = 'right-[-6px]';
        break;
      case orientations.top:
        classnames = 'top-full left-[50%] translate-x-[-50%] -translate-y-2';
        break;
      case orientations.bottom:
        classnames = 'bottom-full left-[50%] translate-x-[-50%] translate-y-2';
        break;

      default:
        break;
    }

    return classnames;
  };

  const classContainer = `w-max absolute z-10 ${setContainerPosition(
    orientation
  )} bg-black text-white text-base px-2 py-1 rounded flex items-center transition-all duration-150 pointer-events-none`;

  const pointerClasses = `bg-black h-3 w-3 absolute -z-10 ${setPointerPosition(
    orientation
  )} rotate-45 pointer-events-none`;

  if (!tooltipText) {
    return children;
  }

  return (
    <div
      className={`relative flex cursor-pointer items-center`}
      onClick={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`${classContainer} ${toolTipContainerClass}`}
        style={{ opacity: 0 }}
        ref={tipRef}
      >
        <div className={pointerClasses} />
        <div>{tooltipText}</div>
      </div>
      {children}
    </div>
  );
};

ToolTip.propTypes = {
  orientation: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
  tooltipText: PropTypes.node.isRequired,
  toolTipContainerClass: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ToolTip;
