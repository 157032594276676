import { useParams, useSearchParams } from "react-router-dom";
import MemberChart from "./MemberChart"
import useQueryParam from "../../utils/hooks/useQueryParam";
import { getCurrentDate } from "../../utils/dateFormatter";
import ClubDetail from "./ClubDetail";
import { useQuery } from "@tanstack/react-query";
import { getTopGainersAndLosers } from "../../utils/dataAlteration";
import Stats from "../home/Stats";
import SemiCircleDonutChart from "../../uikit/semiDonutChart";
import MembershipTier from "./MembershipTier";
import MembershipCalculator from "./MembershipCalculator";

const Details = () => {
    const params = useParams();
    const [paramValue] = useQueryParam('date');
    const { data: mergedData = [] } = useQuery({
        queryKey: ['allMemberMergedData', paramValue || getCurrentDate()],
    });

    const getClubData = (clubName) => {
        return mergedData.data?.find(item => item.club_name.toLowerCase() === clubName.toLowerCase()) || null;
    };

    const clubData = getClubData(params?.clubName);
    const { clubRanking, sortedPoints } = getTopGainersAndLosers(mergedData?.data, params?.clubName);

    return (
        <div>
            <div className="flex flex-col md:flex-row divide-x divide-gray-200  dark:divide-moon-700">
                <div className="w-full md:w-[30%]  p-4">
                    <ClubDetail data={clubData} clubRanking={clubRanking} sortedPoints={sortedPoints} clubName={params?.clubName}
                        startDate="2024-07-31"
                        endDate={paramValue || getCurrentDate()} />
                </div>
                <div className="w-full md:w-[70%] p-4">
                    <div className="hidden md:block">
                        <MemberChart period="daily"
                            clubName={params?.clubName}
                            startDate="2024-07-31"
                            endDate={paramValue || getCurrentDate()} />
                    </div>
                    <div className="p-1 md:p-2">
                        <div className="font-bold mt-4 text-gray-900 dark:text-moon-50 text-lg leading-7">
                            Membership Tier Wise Info
                        </div>
                        <MembershipTier />
                    </div>
                    <div>
                        <MembershipCalculator currentPoints={clubData?.total_points} />
                    </div>
                    {/* <div className="mt-4 overflow-x-auto p-1 md:p-2 md:ml-4">
                        <div className="font-bold text-gray-900 dark:text-moon-50 text-lg leading-7">
                            Recent Happenings
                        </div>
                        <Stats />
                    </div> */}
                </div>
            </div>

        </div>
    )
}

export default Details