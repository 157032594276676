import PropTypes from 'prop-types';

const SortingIcon = ({ direction }) => {
    return (
        <>
            {direction === 'asc' ? (
                <svg
                    width="14px"
                    height="14px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-black dark:stroke-white"
                    transform="rotate(0)"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17 3.25C17.4142 3.25 17.75 3.58579 17.75 4V17.75L19.4 15.55C19.6485 15.2186 20.1186 15.1515 20.45 15.4C20.7814 15.6485 20.8485 16.1186 20.6 16.45L17.6 20.45C17.4063 20.7083 17.0691 20.8136 16.7628 20.7115C16.4566 20.6094 16.25 20.3228 16.25 20V4C16.25 3.58579 16.5858 3.25 17 3.25Z"
                        className="fill-[#1C274C] dark:fill-white"
                    ></path>
                    <path
                        d="M3.25 16C3.25 15.5858 3.58579 15.25 4 15.25H13C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75H4C3.58579 16.75 3.25 16.4142 3.25 16Z"
                        className="fill-[#1C274C] dark:fill-gray-300"
                    ></path>
                    <path
                        opacity="0.7"
                        d="M5.25 11C5.25 10.5858 5.58579 10.25 6 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H6C5.58579 11.75 5.25 11.4142 5.25 11Z"
                        className="fill-[#1C274C] dark:fill-gray-400"
                    ></path>
                    <path
                        opacity="0.4"
                        d="M7.25 6C7.25 5.58579 7.58579 5.25 8 5.25H13C13.4142 5.25 13.75 5.58579 13.75 6C13.75 6.41421 13.4142 6.75 13 6.75H8C7.58579 6.75 7.25 6.41421 7.25 6Z"
                        className="fill-[#1C274C] dark:fill-gray-500"
                    ></path>
                </svg>
            ) : direction === 'desc' ? (
                <svg
                    width="14px"
                    height="14px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-black dark:stroke-white"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17 20.75C17.4142 20.75 17.75 20.4143 17.75 20V6.25005L19.4 8.45005C19.6485 8.78142 20.1186 8.84858 20.45 8.60005C20.7814 8.35152 20.8485 7.88142 20.6 7.55005L17.6 3.55005C17.4063 3.29179 17.0691 3.18645 16.7628 3.28854C16.4566 3.39062 16.25 3.67723 16.25 4.00005V20C16.25 20.4143 16.5858 20.75 17 20.75Z"
                        className="fill-[#1C274C] dark:fill-white"
                    ></path>
                    <path
                        d="M3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H13C13.4142 8.75 13.75 8.41421 13.75 8C13.75 7.58579 13.4142 7.25 13 7.25H4C3.58579 7.25 3.25 7.58579 3.25 8Z"
                        className="fill-[#1C274C] dark:fill-gray-300"
                    ></path>
                    <path
                        opacity="0.7"
                        d="M5.25 13C5.25 13.4142 5.58579 13.75 6 13.75H13C13.4142 13.75 13.75 13.4142 13.75 13C13.75 12.5858 13.4142 12.25 13 12.25H6C5.58579 12.25 5.25 12.5858 5.25 13Z"
                        className="fill-[#1C274C] dark:fill-gray-400"
                    ></path>
                    <path
                        opacity="0.4"
                        d="M7.25 18C7.25 18.4142 7.58579 18.75 8 18.75H13C13.4142 18.75 13.75 18.4142 13.75 18C13.75 17.5858 13.4142 17.25 13 17.25H8C7.58579 17.25 7.25 17.5858 7.25 18Z"
                        className="fill-[#1C274C] dark:fill-gray-500"
                    ></path>
                </svg>
            ) : null}
        </>
    );
};


SortingIcon.propTypes = {
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
};

export default SortingIcon;