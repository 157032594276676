import { formatNumberWithThousandsSeparator, formatNumberWithThousandsSeparatorWithDecimal, getColorCode, roundToTwoDecimals } from '../../utils/commonUtils'
import ArrowIcon from '../../common/ImageJSX/ArrowIcon'
import NumberSpinner from '../../uikit/numberSpinner'
import InfoTable from '../../uikit/infoTable'
import RankingTable from './RankingTable'
import MemberChart from './MemberChart'
import ClubTargetGauge from './ClubTargetGauge'
import Abbreviation from '../../uikit/abbreviation'

const ClubDetail = ({ data, clubRanking, sortedPoints, clubName, startDate, endDate }) => {

    return (<>
        <div className="flex flex-col">
            <div className="flex-1 mt-2" >
                <div className="mb-2 lg:mb-3 flex items-center ">
                    <img
                        className="rounded-full h-8 w-8"
                        alt={`${data?.club_name} logo`}
                        src={data?.image_url}
                    />
                    <h1 className="flex items-center gap-x-1 flex-wrap">
                        <div
                            data-view-component="true"
                            className="text-base 2lg:text-lg font-bold text-gray-900 dark:text-moon-50 leading-7"
                        >
                            {data?.club_name}
                        </div>
                        <span
                            data-view-component="true"
                            className="mt-0.5 font-normal text-gray-500 dark:text-moon-200 text-sm leading-5"
                        >
                            Club
                        </span>
                    </h1>
                    <span
                        data-view-component="true"
                        className="mt-0.5 ml-1 mr-3 2lg:mr-0 inline-flex items-center px-1.5 py-0.5 bg-gray-100 dark:bg-moon-400/20 rounded-md"
                    >
                        <div
                            data-view-component="true"
                            className="text-xs leading-4 text-gray-700 dark:text-moon-200 font-medium"
                        >
                            #{clubRanking}
                        </div>
                    </span>
                </div>
                <div className="mb-2 lg:mb-3 flex items-center ">
                    <div className='flex flex-col '>
                        <div className='text-xs leading-4 text-gray-500 dark:text-moon-200 font-regular'>All Tiers Success Points</div>
                        <div
                            data-view-component="true"
                            className="font-bold mr-2 text-gray-900 dark:text-moon-50 text-3xl md:text-4xl leading-10"
                        >
                            <span
                            >
                                <NumberSpinner digitsCount={4.2} actualNumber={data?.total_points} />
                            </span>
                        </div>
                    </div>
                    <div
                        data-view-component="true"
                        className="font-bold text-gray-900 dark:text-moon-50 text-lg md:text-xl leading-7"
                    >
                        <span
                            className="flex justify-center items-center"
                        >
                            <ArrowIcon direction={data['movement_in_members'] < 0 ? 'down' : 'up'} color={getColorCode(data['movement_in_members'])} />
                            <NumberSpinner digitsCount={1.2} actualNumber={data?.movement_percentage_points} />%
                        </span>
                    </div>
                </div>
                <div className='mt-6'>
                    <ClubTargetGauge />
                </div>

                {/* <div
                    data-view-component="true"
                    className="mb-3 text-xs leading-4 text-gray-500 dark:text-moon-200 font-regular"
                >
                    <div>
                        Membership Tier : ALL
                    </div>
                </div> */}
            </div>

        </div>
        <div className='mt-2 '>
            <div className="mt-6 font-bold text-gray-900 dark:text-moon-50 text-lg leading-7">
                All Tiers Combined Stats
            </div>
            <InfoTable rows={[
                {
                    label: <span>Monthly Movement in <Abbreviation title="Success Point">SP</Abbreviation></span>,
                    value: data?.movement_in_points ? `${formatNumberWithThousandsSeparatorWithDecimal(data?.movement_in_points)} / ${formatNumberWithThousandsSeparatorWithDecimal(data?.movement_percentage_points)}%` : '--',
                },
                {
                    label: 'Movement In Members',
                    value: data?.movement_in_members ? `${formatNumberWithThousandsSeparator(data?.movement_in_members)} / ${formatNumberWithThousandsSeparatorWithDecimal(data?.movement_percentage_members)}%` : '--',
                },
                {
                    label: 'Total Members',
                    value: formatNumberWithThousandsSeparator(roundToTwoDecimals(data?.member_count)),
                },
            ]} />
        </div>
        <div className='mt-4 md:hidden'>
            <MemberChart period="daily"
                clubName={clubName}
                startDate={startDate}
                endDate={endDate} />
        </div>
        {/* <div className='mt-4'>
            <div className="font-bold text-gray-900 dark:text-moon-50 text-lg leading-7">
                Membership Tier Wise
            </div>
            <InfoTable rows={(clubTierLevelInfo?.data ?? []).map((item) => ({
                label: `${item?.membership_tier}`,
                value: <div className='flex justify-center items-center'>
                    <span
                        className="flex justify-center items-center mr-1"
                    >
                        <NumberSpinner digitsCount={4.2} actualNumber={item.total_points} />
                    </span>
                    {' '}  <span className='flex justify-center items-center text-left text-gray-500 dark:text-moon-200 font-medium text-xs leading-5'>
                        (
                        <NumberSpinner digitsCount={1.2} actualNumber={item.movement_percentage_points} />%
                        <ArrowIcon direction={item.movement_percentage_points < 0 ? 'down' : 'up'} color={getColorCode(item.movement_percentage_points)} />
                        )
                    </span>
                    {' '}
                </div>,
                expandable: true,
                expandableContent: [
                    {
                        label: 'Multiplier',
                        value: formatNumberWithThousandsSeparator(roundToTwoDecimals(item?.multiplier)),
                    },
                    {
                        label: 'Members Count',
                        value: formatNumberWithThousandsSeparator(item?.total_members),
                    },
                    {
                        label: 'Total Success Points',
                        value: formatNumberWithThousandsSeparator(roundToTwoDecimals(item?.total_points)),
                    },
                    // {
                    //     label: 'Monthly Movement in SP',
                    //     value: item?.movement_in_points ?
                    //         `${formatNumberWithThousandsSeparator(roundToTwoDecimals(item?.movement_in_points))} / ${formatNumberWithThousandsSeparator(roundToTwoDecimals(item?.movement_percentage_points))}%` : '--',
                    // },
                    // {
                    //     label: 'Members Count',
                    //     value: formatNumberWithThousandsSeparator(item?.total_members),
                    // },
                    // {
                    //     label: 'Movement In Members',
                    //     value: item?.movement_in_members ?
                    //         `${formatNumberWithThousandsSeparator(roundToTwoDecimals(item?.movement_in_members))} / ${formatNumberWithThousandsSeparator(roundToTwoDecimals(item?.movement_percentage_members))}%` : '--',
                    // },
                ]
            }))} />
        </div> */}
        <div className='mt-4'>
            <div className="font-bold text-gray-900 dark:text-moon-50 text-lg leading-7">
                Ranking <span className='text-xs text-gray-400'>(Based On Success Points To Goal)</span>
            </div>
            <RankingTable data={sortedPoints} />
        </div>
    </>)
}

export default ClubDetail