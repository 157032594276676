import React, { useMemo } from 'react'
import useQueryParam from '../../utils/hooks/useQueryParam';
import { getCurrentDate } from '../../utils/dateFormatter';
import useApi from '../../utils/hooks/useApi';
import { useParams } from 'react-router-dom';
import SemiCircleDonutChart from '../../uikit/semiDonutChart';
import SimpleClientTable from '../../uikit/simple-client-table';
import { formatNumberWithThousandsSeparator, formatNumberWithThousandsSeparatorWithDecimal, getColorCode, roundToTwoDecimals } from '../../utils/commonUtils';
import NumberSpinner from '../../uikit/numberSpinner';
import ArrowIcon from '../../common/ImageJSX/ArrowIcon';
import { numericSortingFn } from '../../uikit/simple-client-table/tableUtils';

const MembershipTier = () => {
    const [dateParam] = useQueryParam('date');
    const params = useParams();
    const currentDate = dateParam || getCurrentDate();

    const { data: clubTierLevelInfo, error, isLoading, refetch } = useApi({
        queryKey: ['clubTierLevelInfo', currentDate, params?.clubName],
        // url: `/membership-tiers?period=daily&club_name=ALX&startDate=2024-10-02&endDate=2024-10-02&page=1&page_size=10&paginate=true`,
        url: `/membership-tiers?startDate=${currentDate}&endDate=${currentDate}&club_name=${params?.clubName}&period=daily&paginate=true&page=1&page_size=10`,
        staleTime: 7200000, // 2 hours in milliseconds
        cacheTime: 7200000, // 2 hours in milliseconds
        method: 'GET',
        onSuccess: (data) => {
        },
        onError: (error) => {
            console.error('Error fetching data:', error);
        },
    });

    const columns = useMemo(
        () => [
            {
                accessorKey: 'membership_tier',
                header: 'Membership Tier',
                id: 'membershipTier',
                alignment: 'center',
                cell: ({ row, cell }) => (
                    <>
                        <div className="flex items-center gap-1 shrink-0 min-w-[120px]">
                            <div className='text-left text-gray-500 dark:text-moon-200 font-medium text-base leading-5'>
                                {cell.getValue()}
                            </div>
                            <span className='flex justify-center items-center text-left text-gray-500 dark:text-moon-200 font-medium text-xs leading-5 shrink-0'>
                                (
                                <NumberSpinner digitsCount={1.2} actualNumber={row?.original?.movement_percentage_points} />%
                                <ArrowIcon direction={row?.original?.movement_percentage_points < 0 ? 'down' : 'up'} color={getColorCode(row?.original?.movement_percentage_points)} />
                                )
                            </span>
                        </div>
                    </>
                ),
            },
            {
                accessorKey: 'multiplier',
                header: 'Multiplier',
                id: 'multiplier',
                alignment: 'center',
                sortingFn: numericSortingFn,
                cell: ({ cell }) => formatNumberWithThousandsSeparatorWithDecimal(cell.getValue()),
            },
            {
                accessorKey: 'total_members',
                header: 'Members',
                id: 'total_members',
                alignment: 'center',
                sortingFn: numericSortingFn,
                cell: ({ cell }) => formatNumberWithThousandsSeparator(cell.getValue()),
            },
            {
                accessorKey: 'total_points',
                header: 'Success Points',
                id: 'eomSuccessPoints',
                alignment: 'center',
                sortingFn: numericSortingFn,
                cell: ({ cell }) => formatNumberWithThousandsSeparatorWithDecimal(cell.getValue()),
            },
        ],
        [clubTierLevelInfo?.data]
    );
    const formattedData = clubTierLevelInfo?.data?.map(item => ({
        id: item.membership_tier,
        label: item.membership_tier,
        value: item.total_points
    }));
    return (
        <div className='flex flex-col gap-4 mt-5 md:flex-row divide-x divide-gray-200  dark:divide-moon-700'>
            <div className='w-full md:w-[40%]'>
                <SemiCircleDonutChart data={formattedData} leftMarker="Success Points" rightMarker="Total" />
            </div>
            <div className='w-full md:w-[60%] ring-gray-200 dark:ring-moon-700 ring-2 p-2 md:p-4 rounded-lg'>
                <SimpleClientTable columns={columns} data={clubTierLevelInfo?.data || []} />
            </div>
        </div>
    )
}

export default MembershipTier