export const toastHelper = {
  success: {
    backgroundColor: 'bg-emerald-50',
    icon: (
      <svg
        className=""
        fill="#4CA90C"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="SuccessOutlinedIcon"
      >
        <path
          stroke="#4CA90C"
          d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"
        ></path>
      </svg>
    ),
    textColor: 'text-green-600',
  },
  info: {
    backgroundColor: 'bg-[#e5f6fd]',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="8" cy="8" r="6" stroke="#2c93d6" strokeWidth="1.33333" />
        <path
          d="M7.19995 7.66699H8.19995V11.0003M7.19995 11.0003H9.19995"
          stroke="#2c93d6"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="7.89998" cy="5.50033" r="0.833333" fill="#2c93d6" />
      </svg>
    ),
    textColor: 'text-blue-500',
  },
  warning: {
    backgroundColor: 'bg-yellow-400',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0725 6.80029C9.64577 6.80029 9.2998 7.14625 9.2998 7.57302V10.573C9.2998 10.9998 9.64577 11.3457 10.0725 11.3457C10.4993 11.3457 10.8453 10.9998 10.8453 10.573V7.57302C10.8453 7.14625 10.4993 6.80029 10.0725 6.80029ZM10.0725 12.2548C9.64577 12.2548 9.2998 12.6008 9.2998 13.0276C9.2998 13.4543 9.64577 13.8003 10.0725 13.8003C10.4993 13.8003 10.8453 13.4543 10.8453 13.0276C10.8453 12.6008 10.4993 12.2548 10.0725 12.2548Z"
          fill="#4CA90C"
        />
        <path
          d="M9.13398 3.5C9.51888 2.83333 10.4811 2.83333 10.866 3.5L16.9282 14C17.3131 14.6667 16.832 15.5 16.0622 15.5H3.93782C3.16802 15.5 2.6869 14.6667 3.0718 14L9.13398 3.5Z"
          stroke="#4CA90C"
          strokeWidth="1.5"
        />
      </svg>
    ),
    textColor: 'text-yellow-600',
  },
  error: {
    backgroundColor: 'bg-rose-50',
    icon: (
      <svg
        fill="#d74544"
        className=""
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ErrorOutlineIcon"
      >
        <path
          stroke="#d74544"
          d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        ></path>
      </svg>
    ),
    textColor: 'text-red-600',
  },
};
