import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

const Avatar = ({
  text = '',
  imageUrl,
  status,
  initialCount,
  textSize,
  roundedDivClass = '',
  fullText,
  fixedColor = '',
}) => {
  const [backgroundColor, setBackgroundColor] = useState(fixedColor);

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const getColor = useMemo(() => {
    if (!text) {
      return 'rgb(56, 38, 217)';
    }
    const hash = text.split('').reduce((acc, char) => {
      acc = (acc << 5) - acc + char.charCodeAt(0);
      return acc & acc;
    }, 0);
    const hue = Math.abs(hash) % 360;
    const saturation = 70;
    const lightness = 50;
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
  }, [text]);

  useEffect(() => {
    if (!fixedColor) {
      setBackgroundColor(getColor);
    }
  }, [text]);

  const getInitials = () => {
    if (!text) {
      return '';
    }
    if (fullText) {
      return text.toUpperCase();
    }
    const words = text.split(' ');
    let initials = '';
    if (initialCount === 1) {
      initials = words[0][0];
    } else {
      for (let i = 0; i < words.length; i++) {
        initials += words[i][0];
      }
    }
    if (initials === 'undefined') {
      initials = ' ';
    }
    return initials.toUpperCase();
  };

  const getStatusIcon = () => {
    switch (status) {
      case 'online':
        return (
          <span className="inline-block w-3 h-3 bg-green-500 border-2 border-white rounded-full shadow-sm"></span>
        );
      case 'busy':
        return (
          <span className="inline-block w-3 h-3 bg-red-500 border-2 border-white rounded-full shadow-sm"></span>
        );
      case 'away':
        return (
          <span className="inline-block w-3 h-3 bg-yellow-500 border-2 border-white rounded-full shadow-sm"></span>
        );
      default:
        return null;
    }
  };

  const getAvatarContent = () => {
    // const initials = getInitials();
    return (
      <div
        style={{
          width: `${textSize * 2}px`,
          height: `${textSize * 2}px`,
          position: 'relative',
          fontSize: `${textSize}px`,
          backgroundColor,
        }}
        className={`flex items-center justify-center font-bold text-white rounded-full ${roundedDivClass}`}
      >
        {imageUrl && !imageError ? (
          <img
            src={imageUrl}
            alt={text}
            onError={handleImageError}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            className="rounded-full"
          />
        ) : (
          getInitials()
        )}
        <div style={{ position: 'absolute', bottom: '0px', right: '-4px' }}>
          {getStatusIcon()}
        </div>
      </div>
    );
  };

  return <div className="flex items-center">{getAvatarContent()}</div>;
};

Avatar.propTypes = {
  text: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  fixedColor: PropTypes.string,
  fullText: PropTypes.bool,
  roundedDivClass: PropTypes.string,
  status: PropTypes.oneOf(['online', 'busy', 'away']),
  initialCount: PropTypes.oneOf([1, 2]),
  textSize: PropTypes.number, // New prop for text size
};

Avatar.defaultProps = {
  initialCount: 2,
  textSize: '32',
  fullText: false,
};

export default Avatar;
