const HighlightsCard = ({ title, category, date }) => {
    return (
        <div className="bg-white dark:bg-moon-900 border border-gray-200 dark:border-moon-700 rounded-lg p-2.5 ">
            {/* Category and Date */}
            <div className="text-sm text-gray-500 flex justify-between">
                <span className="text-[#EE7725]">{category}</span>
                <span>{date}</span>
            </div>

            {/* Title */}
            <h3 className="text-md font-semibold mt-2 overflow-hidden text-ellipsis text-gray-900 dark:text-moon-50" style={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical'
            }}>
                {title}
            </h3>
        </div>
    );
};

export default HighlightsCard;
