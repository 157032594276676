import React from 'react';
import PropTypes from 'prop-types';
import ExpandableRow from './ExpandableRow'; // Import the ExpandableRow component

// InfoTable Component
const InfoTable = ({ header, rows }) => {
  return (
    <table className="w-full">
      {header && (
        <thead>
          <tr className="flex justify-between pt-3">
            {header.map((headerLabel, index) => (
              <th
                key={index}
                className="pl-2 text-left text-gray-500 dark:text-moon-200 font-medium text-base leading-5"
              >
                {headerLabel}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody className="grid grid-cols-1 divide-y divide-gray-200 dark:divide-moon-700">
        {rows.map((row, index) => (
          <React.Fragment key={index}>
            {row.expandable ? (
              <ExpandableRow
                label={row.label}
                value={row.value}
                tooltip={row.tooltip}
                expandableContent={row.expandableContent}
              />
            ) : (
              <tr className="flex justify-between py-3">
                <th className="pl-2 text-left text-gray-500 dark:text-moon-200 font-medium text-base leading-5">
                  {row.label}
                </th>
                <td className="pl-2 text-right text-gray-900 dark:text-moon-50 font-semibold text-base leading-5">
                  {row.value}
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

// Prop Types
InfoTable.propTypes = {
  header: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
      tooltip: PropTypes.string,
      expandable: PropTypes.bool,
      expandableContent: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
        })
      ),
    })
  ).isRequired,
};

// Export Component
export default InfoTable;