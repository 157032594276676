import PropTypes from 'prop-types';

const AddIcon = ({ size, color, className }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <g id="Edit / Add_Plus">
                    <path
                        id="Vector"
                        d="M6 12H12M12 12H18M12 12V18M12 12V6"
                        stroke={color}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    ></path>
                </g>
            </g>
        </svg>
    );
};

AddIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string,
};

AddIcon.defaultProps = {
    size: 24,
    color: '#ffffff',
    className: '',
};

export default AddIcon;