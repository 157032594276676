let toasterQueue = [];

export const triggerAlert = ({
  message,
  title,
  type = 'info',
  parentClassName = '',
  children,
}) => {
  const id = Math.random().toString(36).substr(2, 9); // Generate a unique ID for each message
  const toast = { id, type, message, title, parentClassName, children };
  toasterQueue.push(toast);

  setTimeout(() => {
    removeAlert(id);
  }, 5000); // Auto-hide after 5 seconds
  // Render or display alert messages here (you can implement this)
};

export const removeAlert = (id) => {
  toasterQueue = toasterQueue.filter((toast) => toast.id !== id);
};

export const getToasterQueue = () => {
  return toasterQueue;
};

// Attach the triggerAlert function to the window object
window.triggerAlert = triggerAlert;
