/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ArrowIcon from '../../common/ImageJSX/ArrowIcon';
import InfiniteStripe from '../../uikit/infiniiteStripe';
import { formatNumberWithThousandsSeparator, getColorCode } from '../../utils/commonUtils';
import { getCurrentDate } from '../../utils/dateFormatter';
import { useQuery } from '@tanstack/react-query';
import { FAKE_MERGED_DATA } from '../../utils/dummyAPIResponse';
import useQueryParam from '../../utils/hooks/useQueryParam';
import { useLocation, useNavigate } from 'react-router-dom';

const Stripe = () => {
    const [dateParam] = useQueryParam('date');

    const navigate = useNavigate();
    const location = useLocation();

    const { data: mergedData = [], isLoading } = useQuery({
        queryKey: ['allMemberMergedData', dateParam || getCurrentDate()],
        initialData: () => FAKE_MERGED_DATA,
    });

    const handleRowClick = (club_name) => {
        navigate(`/${club_name}${location.search}`);
    }

    return (<div className=''>
        <InfiniteStripe
            items={mergedData?.data}
            renderItem={(item) => <div className='flex cursor-pointer shrink-0 items-center gap-1' onClick={() => { handleRowClick(item?.['club_name']) }}>
                <div>
                    <img src={item?.image_url} className='w-6 h-6 ' width={24} height={24} alt={item['club_name']} />
                </div>
                <div className='text-xs leading-4 text-gray-500  dark:text-moon-200'>
                    {item?.['club_name']} : {` `}
                </div>
                <div className='text-xs font-semibold leading-4  dark:text-moon-50'>
                    {formatNumberWithThousandsSeparator(item?.['movement_in_members'])}
                </div>
                <div>
                    <ArrowIcon direction={item?.['movement_in_members'] < 0 ? 'down' : 'up'} color={getColorCode(item?.['movement_in_members'])} />
                </div>
            </div>} />
    </div>)
}

export default Stripe