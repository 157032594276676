export function getCurrentDate(format = 'yyyy-mm-dd') {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const day = String(currentDate.getDate()).padStart(2, '0');

  switch (format) {
    case 'yyyy-mm-dd':
      return `${year}-${month}-${day}`;
    case 'mm-dd-yyyy':
      return `${month}-${day}-${year}`;
    case 'dd-mm-yyyy':
      return `${day}-${month}-${year}`;
    default:
      return currentDate.toISOString(); // Default to ISO format
  }
}
