import { useState } from 'react';
import PropTypes from 'prop-types';

// ExpandableRow Component
const ExpandableRow = ({ label, value, tooltip, expandableContent }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <>
            <tr className="flex justify-between py-3 cursor-pointer" onClick={toggleExpanded}>
                <th className="pl-2 text-left text-gray-500 dark:text-moon-200 font-medium text-base leading-5">
                    <span className="flex items-center">
                        {label}
                        {tooltip && (
                            <span className="inline-block relative ml-2">
                                <svg className="w-4 h-4 text-gray-500 inline-block cursor-pointer" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22c-5.144 0-9.25-4.106-9.25-9.25S6.856 3.5 12 3.5 21.25 7.606 21.25 12 17.144 22 12 22zm1.75-6.75h-3.5v-1.5h3.5v1.5zm0-3h-3.5v-8h3.5v8z" />
                                </svg>
                                <div className="absolute hidden bg-gray-700 text-white text-base rounded-lg p-2 z-10 tooltip-content">
                                    {tooltip}
                                </div>
                            </span>
                        )}
                    </span>
                </th>
                <td className="flex gap-2 items-center justify-center pl-2 text-right text-gray-900 dark:text-moon-50 font-semibold text-base leading-5">
                    {value}
                    {/* Accordion Arrow SVG */}
                    <svg
                        className={`w-3 h-3 transition-transform duration-200  ${isExpanded ? 'rotate-0 ' : 'rotate-180'} mr-2`}
                        fill="currentColor"
                        height="16px"
                        width="16px"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                    >
                        <g>
                            <path d="M505.752,358.248L271.085,123.582c-8.331-8.331-21.839-8.331-30.17,0L6.248,358.248c-8.331,8.331-8.331,21.839,0,30.17s21.839,8.331,30.17,0L256,168.837l219.582,219.582c8.331,8.331,21.839,8.331,30.17,0S514.083,366.58,505.752,358.248z" />
                        </g>
                    </svg>
                </td>
            </tr>
            {isExpanded && expandableContent && (
                <div className='px-3 divide-y divide-gray-200 dark:divide-moon-700'>
                    {expandableContent.map((subRow, subIndex) => (
                        <tr key={subIndex} className="px-2 flex justify-between py-3 dark:bg-moon-800 ">
                            <th className=" text-left text-gray-500 dark:text-moon-200 font-medium text-sm leading-5">
                                {subRow.label}
                            </th>
                            <td className="pl-2 text-right text-gray-900 dark:text-moon-50 font-semibold text-sm leading-5">
                                {subRow.value}
                            </td>
                        </tr>
                    ))}
                </div>
            )}
        </>
    );
};

// Prop Types
ExpandableRow.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
    tooltip: PropTypes.string,
    expandableContent: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
        })
    ),
};

export default ExpandableRow;
