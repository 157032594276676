import PropTypes from 'prop-types';

const LinearGauge = ({
    start = 0,
    target = 100,
    current = 0,
    label = 'Progress',
    height = '1.5rem',
    width = '100%'
}) => {
    // Calculate progress as a percentage, allowing for values over 100%
    const progressPercentage = ((current - start) / (target - start)) * 100;
    // Calculate the position of the 🎯 icon
    const targetPosition = Math.min(100, (target / current) * 100);

    return (
        <div className="flex flex-col items-center w-full">
            <div
                className="relative bg-gray-100 dark:bg-moon-800 rounded-lg overflow-hidden"
                style={{ width, height }}
            >

                {/* Start Icon */}
                <div className="absolute top-1/2 left-0 transform -translate-y-1/2  z-10">
                    <span role="img" aria-label="start" className="text-lg">📍</span>
                </div>

                {/* Stripped Progress Bar */}
                <div
                    className="absolute top-0 left-0 rounded-lg transition-all duration-300"
                    style={{
                        width: `${Math.min(progressPercentage, 150)}%`,
                        height,
                        backgroundImage: 'linear-gradient(135deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)',
                        backgroundSize: '1rem 1rem', // Adjusts the size of the stripes
                        backgroundColor: '#ee7725'
                    }}
                />

                {/* Centered Label */}
                <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-moon-50 font-semibold text-sm">{label}: {Math.trunc(progressPercentage)} %</span>
                </div>

                {/* Target Marker */}
                <div
                    className="absolute top-1/2 transform -translate-y-1/2 flex items-center"
                    style={{ left: `${targetPosition}%` }}
                >
                    <span role="img" aria-label="target" className="text-lg -ml-6">🎯</span>
                </div>
            </div>
        </div>
    );
};

// Prop Types for validation
LinearGauge.propTypes = {
    start: PropTypes.number,
    target: PropTypes.number,
    current: PropTypes.number,
    label: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default LinearGauge;
