import { Suspense, lazy } from 'react';
import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
} from 'react-router-dom';
import Layout from '../Layout';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import Home from '../modules/home';
import Details from '../modules/details';


const ErrorPage = lazy(() => import('../modules/error/ErrorPage'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
        <Route
          index
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path=":clubName"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Details />
            </Suspense>
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </>
  )
);

export default router;
