import { useMemo } from 'react';
import useApi from '../../utils/hooks/useApi';
import InfoTable from '../../uikit/infoTable';
import { FAKE_MULTIPLIERS_DATA } from '../../utils/dummyAPIResponse';

const MultiplierTable = () => {
    const { data: multiplierData = FAKE_MULTIPLIERS_DATA, error, isLoading, refetch } = useApi({
        queryKey: ['multiplierData'],
        url: `/multipliers?page=1&page_size=10&paginate=false`,
        staleTime: 7200000, // 2 hours in milliseconds
        cacheTime: 7200000, // 2 hours in milliseconds
        method: 'GET',
        onSuccess: (data) => {
            console.log("Data fetched successfully:", data);
        },
        onError: (error) => {
            console.error('Error fetching data:', error);
        },
    });

    const rows = useMemo(() => {
        if (!multiplierData || !multiplierData.data) return [];
        return multiplierData.data.map(item => ({
            label: item.membership_tier,
            value: Number(item.multiplier).toFixed(2), // Ensure two decimal digits
        }));
    }, [multiplierData]);

    return (
        <div className="md:pl-4 my-4 md:mt-auto w-full space-y-4">
            <div className="w-full container flex justify-center items-center flex-col max-w-[100vw] ring-gray-200 dark:ring-moon-700 ring-2 py-1.5 px-2 rounded-xl bg-white dark:bg-moon-900">
                <div className='mt-2 font-semibold text-sm text-gray-900 dark:text-moon-50 p-2'>Membership Tier Value</div>
                <InfoTable rows={rows} />
            </div>
        </div>
    );
};

export default MultiplierTable;