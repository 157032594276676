import PropTypes from 'prop-types';

// Define some utility classes for different variants, colors, and sizes
const variants = {
  text: 'bg-transparent hover:bg-gray-200',
  contained:
    'bg-[#FF9900] transition-colors duration-300 hover:bg-[#e27200] text-white border-[#FF9900] hover:bg-[#e27200]',
  secondaryContained:
    'bg-[#f8c94633] transition-colors duration-300 hover:bg-[#f8c94647] text-[#F8C946] border-[#f8c94633] hover:bg-[#f8c94647]',
  outlined:
    'bg-transparent transition-colors duration-300 border border-gray-300 hover:bg-gray-200 text-[#626669] border-[#626669] hover:bg-[#efefef] hover:text-[#626669]',
};

const sizes = {
  regular: 'text-base px-4 py-2',
  small: 'text-base px-3 py-1',
  large: 'text-lg px-5 py-3',
};

// Define the button component
const Button = ({
  variant = 'contained',
  startIcon = null,
  endIcon = null,
  size = 'regular',
  children,
  disabled = false,
  containerClass = '',
  ...rest
}) => {
  // Use the utility classes based on the props
  const variantClass = variants[variant] || variants.contained;
  const sizeClass = sizes[size] || sizes.regular;

  // Render the button with the appropriate classes and props
  return (
    <button
      disabled={disabled}
      className={`flex items-center justify-center rounded-lg ${disabled ? 'opacity-40' : ''} ${variantClass} ${sizeClass} ${containerClass}`}
      {...rest}
    >
      <>
        {startIcon && (
          <span className={children ? 'mr-2' : ''}>{startIcon}</span>
        )}
        {children}
        {endIcon && <span className="ml-2">{endIcon}</span>}
      </>
    </button>
  );
};

// Define the prop types for the button component
Button.propTypes = {
  variant: PropTypes.oneOf([
    'text',
    'contained',
    'secondaryContained',
    'outlined',
  ]),
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  disabled: PropTypes.bool,
  containerClass: PropTypes.string,
  size: PropTypes.oneOf(['regular', 'small', 'large']),
  children: PropTypes.node,
  onClick: PropTypes.func,
  ...PropTypes.object,
};

Button.defaultProps = {
  variant: 'contained',
};

export default Button;
