import logo from './images/logo.png';
import externalLink from './images/externalLink.svg';
import { Link, NavLink } from 'react-router-dom';
import Stripe from './Stripe';
import Avatar from '../../uikit/avatar';
import DateIcon from './DateIcon';
import DarkModeToggle from './DarkModeToggle';

// import { useGlobal } from '../../utils/contexts/GlobalContext';

function Header() {
  // const { state } = useGlobal();
  // const location = useLocation();
  return (
    <header className="sticky top-0 z-30 flex-shrink-0 bg-white dark:bg-moon-900 border-b border-gray-200 dark:border-moon-700">
      <div className="flex justify-between w-full p-3">
        <NavLink to={`/`}>
          <div className="flex items-center h-9 gap-x-2 max-xs:gap-x-1">
            <img alt="logo" width={50} height={32} src={logo} />
            <span className="text-[#a51d36] text-md md:text-xl font-semibold">
              PERFORMER
            </span>
          </div>
        </NavLink>
        <div className='flex gap-4 justify-center items-center'>
          <Link to="https://crunch.supportsystem.com/open.php" target="_blank" rel="noopener noreferrer" >
            <div className="hidden md:flex hover:underline 2lg:block dark:!text-moon-50 text-gray-700  font-semibold text-sm leading-5 justify-center items-center">
              <div>Helpdesk</div><img alt="logo" width={14} height={14} src={externalLink} />
            </div>
          </Link>
          <Link to="https://app.reachreporting.com" target="_blank" rel="noopener noreferrer" >
            <div className="hidden md:flex 2lg:block dark:!text-moon-50 hover:underline text-gray-700  font-semibold text-sm leading-5 justify-center items-center">
              <div>Reach Reporting</div> <img alt="logo" width={14} height={14} src={externalLink} />
            </div>
          </Link>
          <div>
            <DarkModeToggle />
          </div>
          <div>
            <DateIcon />
          </div>
          <div className='hidden md:flex'>
            <Avatar text="Admin" imageUrl={'https://avatar.iran.liara.run/public/40'} textSize={15} />
          </div>
        </div>
      </div>
      <div className='border-y border-gray-200 dark:border-moon-700 p-2'>
        <Stripe />
      </div>
    </header>
  );
}

export default Header;
