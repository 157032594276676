import React from 'react'
import InfoTable from '../../uikit/infoTable'
import ArrowIcon from '../../common/ImageJSX/ArrowIcon'
import { getColorCode, roundToTwoDecimals } from '../../utils/commonUtils'
import NumberSpinner from '../../uikit/numberSpinner'
import Abbreviation from '../../uikit/abbreviation'

const RankingTable = ({ data }) => {
    return (
        <><InfoTable header={['Club Name', <span key="header-rank"><Abbreviation title="Success Point">SP</Abbreviation>&apos;s To Goal</span>]} rows={(data ?? []).map((item, index) => ({
            label: <div className='flex justify-center  items-center'>
                <div
                    data-view-component="true"
                    className="mt-0.5 ml-1 mr-3 2lg:mr-0 inline-flex items-center px-1.5 py-0.5 bg-gray-100 dark:bg-moon-400/20 rounded-md"
                >
                    <div
                        data-view-component="true"
                        className="text-xs leading-4 text-gray-700 dark:text-moon-200 font-medium"
                    >
                        #{index + 1}
                    </div>
                </div>
                <div>
                    <img src={item?.image_url} className='w-6 h-6 ' width={24} height={24} alt={item?.['club_name']} />
                </div>
                <div className='text-xs leading-4 text-gray-500 dark:text-moon-200'>
                    {item?.['club_name']}
                </div>
                {index === 0 && <div
                    data-view-component="true"
                    className="-mt-1 ml-1 mr-3 2lg:mr-0 inline-flex items-center px-1.5 py-0.5 m rounded-md"
                >
                    <div
                        data-view-component="true"
                        className="text-sm leading-4 text-gray-700 dark:text-moon-200 font-medium"
                    >
                        👑
                    </div>
                </div>}
            </div>,
            value: <span
                className="flex justify-center items-center"
            >
                <ArrowIcon direction={item.movement_percentage_points < 0 ? 'down' : 'up'} color={getColorCode(item.movement_percentage_points)} />
                <NumberSpinner digitsCount={1.2} actualNumber={item.points_to_target} />
            </span>
        }))} /></>
    )
}

export default RankingTable